// src/data/Media.js

import guncel3 from '../heroSection2.png';
import guncel2 from '../heroSection.png';
import guncel from '../heroSection3.png';
import resim1 from '../galery/resim1.jpg';
import resim2 from '../galery/resim2.jpg';
import resim3 from '../galery/resim3.jpg';
import resim4 from '../galery/resim4.jpg';
import resim5 from '../galery/resim5.jpg';
import resim6 from '../galery/resim6.jpg';
import resim7 from '../galery/resim7.jpg';
import resim8 from '../galery/resim8.jpg';
import resim9 from '../galery/resim9.jpg';
import haber1 from '../haber/haber1.jpg';
import haber2 from '../haber/haber2.jpg';
import haber3 from '../haber/haber3.jpg';


const Media = {
    carousel: {
        images: [guncel, guncel2, guncel3],
        captions: [
            { title: '', text: '' },
            { title: '', text: '' },
            { title: '', text: '' },
        ]
    },
    news: [
        {
            title: 'Şehit Anne Ve Babalarımız İle Birlikte...',
            text: '',
            date: '2024-09-10',
            image: haber1,
            link: '/news/1'
        },
        {
            title: 'Vatan Yürekli İnsan Sedat Şahin...',
            text: '.',
            date: '2024-09-09',
            image: haber2,
            link: '/news/2'
        },
        {
            title: 'Şehitlerimiz',
            text: '',
            date: '2024-09-08',
            image: haber3,
            link: '/news/3'
        }
    ],
    announcements: [
        {
            title: 'Vefa Ziyaretimiz',
            detail: 'Şehit ve gazi ailelerimizi evlerinde ziyaret ederek, her zaman yanlarında olduğumuzu bir kez daha dile getirdik. Birlik ve beraberlik içinde geçen ziyaretlerimizde duygu dolu anlar yaşandı.',
            date: '2024-09-05',
            link: '/announcement'
        },
        {
            title: 'Anma Töreni Duyurusu',
            detail: 'Aziz şehitlerimiz ve kahraman gazilerimizi anmak için düzenleyeceğimiz törene tüm halkımızı davet ediyoruz. Katılımınız bizler için onur verici olacaktır.',
            date: '2024-09-05',
            link: '/announcement'
        },
        {
            title: 'Başkanımızdan Teşekkür Mesajı',
            detail: 'Vakfımıza gösterilen destek ve ilgi için tüm gönüldaşlarımıza teşekkür ederiz. Birlikte daha güçlü ve kararlı adımlarla yürümeye devam edeceğiz.',
            date: '2024-09-05',
            link: '/announcement'
        },
        {
            title: 'Yardım Kampanyası Başladı',
            detail: 'Vakfımızın düzenlediği yeni yardım kampanyasında şehit ve gazi ailelerimiz için yardımlar toplanmaya başlandı. Destek olmak isteyen tüm gönüllülerimizi katkıda bulunmaya davet ediyoruz.',
            date: '2024-09-05',
            link: '/announcement'
        },
        {
            title: 'Genel Kurul Toplantısı Duyurusu',
            detail: 'Vakfımızın yıllık genel kurul toplantısı yaklaşıyor. Tüm üyelerimizi, gelecek dönem hedeflerini ve projelerini konuşmak üzere toplantımıza davet ediyoruz.',
            date: '2024-09-05',
            link: '/announcement'
        }
    ],
    galleries: [
        [resim1, resim2, resim3],
        [resim4, resim5, resim6],
        [resim7, resim8, resim9],
    ],
    videos: [
        { url: 'https://www.youtube.com/embed/wEcEc5TAOL0' },
        { url: 'https://www.youtube.com/embed/Odl2qwbrFwg' },
    ],
    branches: [
        {
            name: 'Tusag Ankara',
            address: 'Adres 1, Ankara, TR',
            phone: '+90 123 456 7890',
            email: 'sube1@example.com',
            authorizedPerson: 'Ali Yılmaz',
            authorizedPhone: '+90 111 222 3333',
            authorizedEmail: 'ali.yilmaz@example.com',
        },
        {
            name: 'Tusag Diyarbakır',
            address: 'Adres 2, Diyarbakır, TR',
            phone: '+90 987 654 3210',
            email: 'sube2@example.com',
            authorizedPerson: 'Mustafa Darıviranlı',
            authorizedPhone: '+90 444 555 6666',
            authorizedEmail: 'ayse.demir@example.com',
        },
        {
            name: 'Tusag Malatya',
            address: 'Adres 3, Malatya, TR',
            phone: '+90 555 555 5555',
            email: 'sube3@example.com',
            authorizedPerson: 'Mehmet Kara',
            authorizedPhone: '+90 777 888 9999',
            authorizedEmail: 'mehmet.kara@example.com',
        }
    ],
    bankAccounts: [
        {
            bankName: 'DENİZBANK - TL',
            accountNumber1: '2710 - 18332406 - 351',
            iban: 'TR45 0013 4000 0183 3240 6000 01',
            branchAddress: 'MİTHATPAŞA ŞUBESİ, Ankara, TR'
        },
        {
            bankName: 'DENİZBANK  - EUR',
            accountNumber: '2710 - 18332406 - 352',
            iban: 'TR18 0013 4000 0183 3240 6000 02',
            branchAddress: 'MİTHATPAŞA ŞUBESİ, Ankara, TR'
        },
        {
            bankName: 'DENİZBANK  - USD',
            accountNumber: '2710 - 18332406 - 353',
            iban: 'TR88 0013 4000 0183 3240 6000 03',
            branchAddress: 'MİTHATPAŞA ŞUBESİ, Ankara, TR'
        }
    ],
    rightsData: [
        {
            id: 1,
            title: 'Koruyucu Sisteminden Doğan Haklar',
            details: (
                <>
                    <p><strong>1- Aylık Bağlanması</strong></p>
                    <p>
                        55 yaşını dolduranlardan, 15 yıl ve daha fazla hizmeti olan geçici köy korucularına aylık bağlanmaktadır. 15 yıldan fazla hizmeti olanlara, 15 yılı aşan her yıl için 200 gösterge rakamı ilave edilir. Ancak bu ilaveler 3000 gösterge rakamını geçemez.
                    </p>
                    <ul>
                        <li>55 yaşını doldurmuş ve 15 yıl hizmeti olanlara (gösterge x memur maaş katsayısı) aylık bağlanmaktadır.</li>
                        <li>15 yıldan fazla hizmeti olanlara 200 gösterge ilave edilir (3000 göstergeyi geçemez).</li>
                        <li>2007'den önce göreve başlayanlara 10 yıl hizmet sonunda aylık bağlanabilir.</li>
                    </ul>
                    <p>İlgili dosyalar Valilik tarafından hazırlanır ve SGK tarafından işlenir.</p>

                    <p><strong>2- Sağlık Yardımı</strong></p>
                    <p>
                        Sosyal güvence altında olmayan geçici köy korucularına ve ailelerine yeşil kart verilir. 5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası Kanunu kapsamına alınmışlardır.
                    </p>

                    <p><strong>3- Tazminat Ödenmesi</strong></p>
                    <p>
                        55 yaşını doldurmamış ve 15 yıldan az hizmeti olan geçici köy korucularına tazminat ödenir.
                    </p>

                    <p><strong>4- Nakdi Tazminat</strong></p>
                    <p>
                        Görev sırasında yaralanma veya ölüm halinde, nakdi tazminat ödenir.
                    </p>

                    <p><strong>5- Ölüm Yardımı</strong></p>
                    <p>
                        Görev sırasında vefat edenlerin ailelerine ölüm yardımı ödenir.
                    </p>

                    <p><strong>6- Şehit ve Malul Yakınlarına İş İmkanı</strong></p>
                    <p>
                        Şehit ya da malul köy korucularının yakınlarına iş imkanı sağlanmaktadır.
                    </p>
                </>
            )
        },
        {
            id: 2,
            title: 'Şehit Yakını ve Gazi Malullerin Çocuklarının Askerlikten Muaf Olma Hakkı',
            details: (
                <>
                    <p><strong>1- Askerlikten Muafiyet</strong></p>
                    <p>
                        5837 sayılı Kanuna göre şehit ve malul yakınları askerlikten muaf tutulur. Muafiyet başvurusunda, anne ve baba taleplerine göre kardeşlerden biri muaf olabilir.
                    </p>
                    <p>
                        Eğer baba ya da anne hayatta değilse, yaşayan ebeveynin talebiyle kardeşlerden biri silah altına alınmaz.
                    </p>
                    <p>
                        Terörle Mücadele Kanunu kapsamında hayatını kaybedenlerin erkek çocukları da istekli olmadıkça askerlik hizmetine alınmaz.
                    </p>
                    <p>
                        Bu haktan yararlanmak isteyen vatandaşlar, Askerlik Şubelerine başvurmalıdır.
                    </p>
                </>
            )
        },
        {
            id: 3,
            title: 'Şehit Yakını ve Gazilerimizin Vergi ve Gümrük Sisteminden Doğan Hakları',
            details: (
                <>
                    <p><strong>1- GELİR VERGİSİ KANUNDA YER ALAN HÜKÜMLER:</strong></p>
                    <p><strong>A-Vatan Hizmetleri Yardımlarında istisna</strong></p>
                    <p>193 sayılı Gelir Vergisi Kanununun 26. Maddesinde “Aşağıdaki yazılı vatan hizmetleri yardımları Gelir Vergisi'nden müstesnadır:</p>
                    <ol>
                        <li>Harb malullüğü zamları;</li>
                        <li>Harb malullerine ve şehit, dul ve yetimlerine verilen tekel bayileri;</li>
                        <li>Şehitlerin dul ve yetimlerine şehit, dul ve yetimi sıfatıyla yapılan bilumum ödemeler...</li>
                        <li>Vatan hizmetleri tertibinden bağlanan aylıklar ile ödenen mükâfatlar.</li>
                    </ol>
                    <p><strong>B- Sakatlık İndirimi</strong></p>
                    <p>193 sayılı Gelir Vergisi Kanunun 31. Maddesinde “Çalışma gücünün asgarî % 80'ini kaybetmiş bulunan hizmet erbabı birinci derece sakat...</p>
                    <ol>
                        <li>Birinci derece sakatlar için 440.000.000 lira,...</li>
                        <li>İkinci derece sakatlar için 220.000.000 lira,...</li>
                        <li>Üçüncü derece sakatlar için 110.000.000 liradır.</li>
                    </ol>
                    <p>Aynı kanunun 89/3 ncü maddesinde de: “Serbest meslek faaliyetinde bulunan veya basit usulde vergilendirilen özürlülerin beyan edilen gelirlerine...</p>
                    <p><strong>2- KATMA DEĞER VERGİSİNDE SAĞLANAN AVANTAJLAR</strong></p>
                    <p>3065 sayılı Katma Değer Vergisi Kanununda, özürlülerin eğitimleri, meslekleri, günlük yaşamları için özel olarak üretilmiş her türlü araç-gereç ve özel bilgisayar programları Katma Değer Vergisinden istisna edilmiştir...</p>
                    <p><strong>3- MALUL ve GAZİ (ENGELLİ)LERE TANINAN YURT İÇİ ARAÇ ALIMINDA ÖTV'DEN MUAF OLMA HAKKI</strong></p>
                    <p>4760 sayılı ÖTV Kanununun 7/2. maddesi ile sakatlık derecesi % 90 veya daha fazla olan malul ve engelliler...</p>
                    <p><strong>4- SAYILI DAMGA VERGİSİ KANUNUNDA YER ALAN HÜKÜM</strong></p>
                    <p>488 sayılı Damga Vergisi Kanununa ekli (2) sayılı tablonun “IV-Ticari ve medeni işlerle ilgili kağıtlar” başlıklı bölümün...</p>
                    <p><strong>5- SAYILI HARÇLAR KANUNUNDA YER ALAN HÜKÜMLER</strong></p>
                    <p>492 sayılı Harçlar Kanununun 59 ncu maddesine eklenen (1) bendinde, “2985 sayılı Toplu Konut Kanununa...</p>
                    <p><strong>6- MALUL ve ENGELLİLERE TANINAN MOTORLU TAŞITLAR VERGİSİ İSTİSNASI</strong></p>
                    <p>197 sayılı Motorlu Taşıtlar Vergisi Kanunu'nun 4 üncü maddesinin (c) bendinde sakatlık dereceleri % 90 ve daha fazla olan kişilerin...</p>
                    <p><strong>1 - Sakatlık Dereceleri % 90 ve Daha Fazla Olan Kişilere Ait Taşıtlarda İstisna Uygulaması</strong></p>
                    <p>Sakatlık dereceleri % 90 veya daha fazla olan kişilerin bu durumlarını tam teşekküllü devlet hastanesinden alınmış olan sağlık kurulu raporu ile belgelendirmeleri halinde, söz konusu kişilerin adlarına kayıt ve tescil edilmiş olan taşıtlar motorlu taşıtlar vergisinden istisna tutulacaktır.</p>
                    <p>Bu istisnadan yararlanmak için, söz konusu kişilere ait taşıtın özel tertibatlı veya özel tertibatlı hale getirilmiş olması şartı aranmayacaktır.</p>

                    <p><strong>2 - Sakatlık Dereceleri % 90'ın Altında Olan Kişilere Ait Taşıtlarda İstisna Uygulaması</strong></p>
                    <p>Sakatlık dereceleri % 90'dan az olan kişilerin bu durumlarını; tam teşekküllü devlet hastanesinden alınan sağlık kurulu raporu ile belgelendirmeleri ve kendi adlarına kayıt ve tescilli olan taşıtların engellilik haline uygun özel tertibatlı veya özel tertibatlı hale getirilmiş taşıtlar olduğunu belirten "Motorlu Araç Tescil Belgesi"nin ilgili tescil kuruluşlarınca onaylanmış örneği ile "Araçlar İçin Teknik Belge" ve "Proje Raporu"nun aslı veya noter onaylı örneğini ilgili vergi dairelerine ibraz etmeleri halinde bu istisna hükmünden yararlanılabilecektir.</p>

                    <p><strong>3 - Malûl ve Engelliler İstisnası İle İlgili Bildirim</strong></p>
                    <p>Söz konusu kişilerin bu istisnadan yararlanabilmeleri için; 21 Seri No.lu Genel Tebliği ekinde yer alan formu düzenleyerek (Bildirim Formu'nun internetteki www.gib.gov.tr adresinde bulunan örneğinin düzenlenmesi mümkün bulunmaktadır.) istisnadan yararlanmak için gerekli olan diğer belgeler ile birlikte ilgili vergi dairesine vermeleri gerekmektedir.</p>

                    <p><strong>7 - VERASET VE İNTİKAL VERGİSİ KANUNUNDA SAĞLANAN AVANTAJLAR</strong></p>
                    <p>7338 sayılı Veraset ve İntikal Vergisi Kanununun 4 üncü maddesinin (g) bendinde, harp malulleri ile şehit yetimlerine tekel bey'iyelerinden ödenen paralar ile aynı maddenin (b) bendinde, füruğ ve eşe tanınan istisna tutarının, anılan maddenin (h) bendinde ise harbde veya eşkıya müsademelerinde, manevra ve talimler esnasında veyahut bunlardan aldığı yaralar neticesinde ölen subay, astsubay ve erlerin (jandarma dahil) ve bunun gibi vazife esnasında ölen emniyet mensuplarının füruğ ve eşlerine ve ana ve babalarına intikal eden bütün mallar kıymetinden (b) bendinde kabul olunan miktarın bir mislinin istisna edileceği belirlenmiştir.</p>
                    <p>Buna göre; harp malulleri ile şehit yetimlerine tekel bey'iyelerinden ödenen paralar veraset ve intikal vergisinden istisnadır.</p>
                    <p>Harpte veya eşkıya çatışmalarında, manevra ve talimler esnasında veyahut bunlarda aldığı yaralar neticesinde ölen subay, astsubay ve erlerin (jandarma dahil) ve bunun gibi vazife esnasında ölen emniyet mensuplarının eşine ve çocuklarına, 2009 yılı için geçerli olan 107.604 TL'nin iki misli (215.208 TL), eşin tek başına mirasçı olması halinde eşe isabet eden miras hissesinin 215.336 TL'nin iki misli (430.672 TL) veraset ve intikal vergisinden istisnadır.</p>
                    <p>Ayrıca, harpte veya eşkıya çatışmalarında, manevra ve talimler esnasında veyahut bunlarda aldığı yaralar neticesinde ölen subay, astsubay ve erlerin (jandarma dahil) ve bunun gibi vazife esnasında ölen emniyet mensuplarının ana ve babalarına intikal eden malların bütün kıymetlerinden 2009 yılı için geçerli olan 107.604 TL veraset ve intikal vergisinden istisna bulunmaktadır.</p>

                    <p><strong>8 - BELEDİYE GELİRLERİ VE EMLAK VERGİSİ KANUNU:</strong></p>
                    <p>1319 sayılı Emlak Vergisi Kanunu'nun 8 inci maddesi hükmüne dayanarak; gazilere, şehitlerin dul ve yetimlerine ait brüt 200 m2'yi geçmeyen tek meskenin (intifa hakkına sahip olunması hali dahil) bina vergisi oranının 2007 ve müteakip yıllar için, sıfıra indirilmesine dair düzenleme Bakanlar Kurulunca 20.12.2006 tarihinde kararlaştırılmıştır. Bu karar 01.01.2007 tarihinden geçerli olmak üzere yürürlüğe girmiştir.</p>
                    <p>Meskenlere ait sıfır oranlı bina vergisinden faydalanmak isteyen şehit yakını ve gazilerimizin, “Tek Meskeni Olan (İntifa hakkına sahip olanlar dahil) Şehitlerin Dul ve Yetimlerine ve Gazilere Ait Form” ile şehit dul ve yetimi veya gazi olduklarına dair belgeyle birlikte ilgili Belediye Başkanlıklarına müracaat etmeleri gerekmektedir.</p>

                    <p><strong>9 - GAZİ VE MALULLERE TANINAN GÜMRÜKSÜZ ARAÇ İTHAL HAKKI</strong></p>
                    <p>1615 sayılı Gümrük Kanunu 8/14 –a maddesi uyarınca malul ve sakatlar tarafından ithal edilecek kara nakil vasıtalarına muafiyet getirilmiştir. Bu vasıtalar motorlu veya motorsuz koltuklar, bisikletler, motosiklet veya binek otomobilleri kapsamaktadır. Gümrük vergisinden muaf olunabilmesi için ithalatın mutlaka malul veya sakat kişi tarafından yapılması gerekmektedir. Ayrıca malul veya sakatın ortopedik bir rahatsızlığının olması ve ithal edilecek vasıtaların özel surette imal edilmiş hareket ettirici tertibatının bulunması şartı aranmaktadır. Binek otomobillerin ithali için Ankara Hibe ve Sakat Araçları Gümrük Müdürlüğüne, diğer araç ve gereçler için ise illerde bulunan Gümrük Müdürlüklerine müracaat edilmesi gerekmektedir.</p>

                </>

            )
        },
        {
            id: 4,
            title: 'İçişleri Bakanlığı Emniyet Genel Müdürlüğü Tarafından Sağlanan Haklar',
            details: (
                <>
                    <p><strong>1- ÖLÜM YARDIMI VE PERSONEL ŞEHİT YARDIMI</strong></p>
                    <p>
                        Türk Polis Teşkilatını Güçlendirme Vakfı Genel Müdürlüğünce, 3713 Sayılı Terörle Mücadele Kanunu kapsamında Emekli Sandığı Genel Müdürlüğünce şehitliği kabul edilen personelin varislerine karşılıksız ölüm yardımı yapılmaktadır.
                    </p>
                    <p>
                        Ayrıca, Emniyet Genel Müdürlüğü Sosyal Hizmetler Dairesi Başkanlığınca şehit personelin hak sahiplerine, personel şehit yardımı yapılmaktadır.
                    </p>

                    <p><strong>2- POLİS AKADEMİSİ, POLİS KOLEJİ ve POLİS MESLEK YÜKSEK OKULLARINA GİRMEDE KOLAYLIK</strong></p>
                    <p>
                        2330 Sayılı Nakdi Tazminat ve Aylık Bağlanması Hakkındaki Kanun kapsamına giren şehit personelin çocuklarına Polis Akademisi (Düşük puan uygulaması, 245 yerine 185 puanla alınmaktadır), Polis Koleji (Almış olduğu puan üzerine %5 artı puan veriliyor) ve Polis Meslek Yüksekokullarına Girişte kolaylık (Gerekli puanı almaları halindekontenjan haricinde yerleştiriliyorlar) sağlanmaktadır.
                    </p>

                    <p><strong>3- STANDART DIŞI TABANCA VERİLMESİ</strong></p>
                    <p>
                        Şehit personelin geride kalan eş veya çocuklarına, standart dışı tabanca verilmektedir.
                    </p>

                    <p><strong>4- EMNİYET BÜNYESİNDEKİ SOSYAL TESİSLERDEN ÜCRETSİZ KONAKLAMA VE YEMEK BEDELLERİNDE KOLAYLIK</strong></p>
                    <p>
                        Emniyet Genel Müdürlüğü Sosyal Tesisleri Kuruluş Görev ve Çalışma Yönetmeliği gereği şehit personelin dul ve yetimleri Emniyet Teşkilatı bünyesinde bulunan sosyal tesislerde ücretsiz konaklamakta, yemek bedelinin ise %50'si alınmaktadır.
                    </p>

                    <p><strong>5- EMNİYET GENEL MÜDÜRLÜĞÜ YÜKSEK ÖĞRENİM ÖĞRENME YURTLARINDA ÜCRETSİZ KALMA</strong></p>
                    <p>
                        Emniyet Genel Müdürlüğü Yüksek Öğrenim Öğrenci Yurtlarında şehit personel çocukları ücretsiz kalmakta, yemek giderleri ise Türk Polis Teşkilatını Güçlendirme Vakfınca karşılanmaktadır.
                    </p>

                    <p><strong>6- ŞEHİT PERSONELİN MEZARININ ÜCRETSİZ YAPTIRILMASI</strong></p>
                    <p>
                        Durumu 2330 Sayılı Nakdi Tazminat ve Aylık Bağlanması Hakkındaki Kanun hükümleri kapsamına giren şehit personelin mezarı o yılın bütçe ödeneklerine göre Emniyet Genel Müdürlüğünce yaptırılmaktadır.
                    </p>

                    <p><strong>7- ŞEHİT PERSONELİN ZATİ DEMİRBAŞ SİLAHININ KANUNİ MİRASÇILARINA VERİLMESİ</strong></p>
                    <p>
                        Şehit personelin zati demirbaş tabancası kanuni mirasçılarına verilmektedir.
                    </p>

                    <p><strong>8- MALUL OLAN PERSONELE MALULLUK YARDIMI YAPILMASI</strong></p>
                    <p>
                        Emniyet Genel Müdürlüğü Sosyal Hizmetler Dairesi Başkanlığınca görev malulü personele malul yardımı yapılmaktadır.
                    </p>

                    <p><strong>9- GÖREV MALULU PERSONELDEN İDARİ POLİS OLARAK ÇALIŞANLARIN ŞARK GÖREVİNDEN MUAF TUTULMASI</strong></p>
                    <p>
                        Görev Malulü personelden İdari Polis Olarak Çalışanlar Şark Görevinden muaf tutulmaktadırlar.
                    </p>

                    <p><strong>10- GAZİ, MALUL VE ENGELLİLERE AİT ARAÇLARIN PARK ETMELERİNDE SAĞLANAN KOLAYLIKLAR</strong></p>
                    <p>
                        İçişleri Bakanlığı Emniyet Genel Müdürlüğünce çeşitli tarihlerde yayımlanan ve en son 13.10.2006 tarih ve 171808 sayılı yazı ile; gazi, malul engellilere ait araçlar için park yerlerinin tahsis edilmesi konusunda gerekli çalışmaların yapılması ve trafikte kendilerine gerekli kolaylığın sağlanması istenilmiştir.
                    </p>
                    <p>
                        Vatanın bölünmez bütünlüğünün müdafaası esnasında sakatlanıp gazi olanların, plakasında engelli işareti bulunan araçlarıyla hastane, banka, postane, alışveriş merkezleri gibi yerlere gidiş gelişleriyle yaptıkları park esnasında, gazi olduklarını gösteren belgelerini araçlarının ön camında bulundurmaları halinde, özürlüler için park kartı uygulanmasında faydalanmaları sağlanacaktır.
                    </p>
                    <p>
                        Tescil plakalarının üzerinde engellilere mahsus ayrım işareti bulunan araçların park etmelerine yardımcı olunacak, engelli araçları için tahsis edilen park yerlerine başka sürücülerin park etmeleri önlenecek, aksine hareket edenler hakkında 2918 sayılı Karayolları Kanununun 61/O maddesi gereğince gerekli cezai işlem uygulanacaktır.
                    </p>
                </>
            )
        },
        {
            id: 5,
            title: 'Eğitim - Öğretim Hakları',
            details: (
                <>

                    <p>
                        <strong>1- SOSYAL GÜVENLİK KURUMU (SGK) TARAFINDAN YAPILAN EĞİTİM YARDIMI</strong>
                        5434 sayılı T.C. Emekli Sandığı Kanununun 79 nolu ek maddesine göre, harp malulleri ile şehitlerin çocuklarına; ilköğretim öğrencileri için (1250), lise öğrencileri için (1875) ve yüksek öğrenim öğrencileri için (2500) gösterge rakamlarının memur aylık katsayısı ile çarpımı sonucu bulunacak tutar kadar her ay eğitim ve öğretim yardımı yapılır. Bu yardımlar yılda bir kez olmak üzere 1 Eylül-31 Aralık tarihleri arasında müracaat etmeleri halinde toptan ödenir.
                        Bu haktan yararlanmak isteyenler, öğrenim durum belgeleriyle birlikte, Sosyal Güvenlik Kurumu Başkanlığına başvurmaları gerekir. Öğrenim yardımı, iki yıl üst üste sınıfta kalanlara verilmez.
                    </p>

                    <p>
                        <strong>2- İLKÖĞRETİM VE ORTAÖĞRETİMDE SINAVSIZ PARASIZ YATILILIKTAN YARARLANMA, BURSLU ÖĞRENCİ OKUTMA VE DİĞER SOSYAL YARDIMLAR</strong>
                        İlköğretim ve Ortaöğretimde Parasız Yatılı veya Burslu Öğrenci Okutma ve Bunlara Yapılacak Sosyal Yardımlara İlişkin 2684 sayılı Kanunun 5. maddesinde sınavsız olarak parasız yatılı alınacak öğrenciler ile ilgili hükümler bulunmaktadır.
                    </p>

                    <p>
                        <strong>3- MİLLİ EĞİTİM BAKANLIĞINA BAĞLI ÖZEL EĞİTİM KURUMLARINDA ÜCRETSİZ OKUMA HAKKI</strong>
                        Millî Eğitim Bakanlığınca hazırlanan ve özel öğretim kurumlarında şehit ve malûl gazi çocuklarının ücretsiz olarak okumalarına imkân veren “Özel Öğretim Kurumlarında Ücretsiz veya Burslu Okutulacak Öğrenci ve Kursiyerler Hakkında Yönetmelik”, 11/2/2009 tarihli ve 27138 sayılı Resmi Gazete'de yayımlanarak yürürlüğe girmiştir.
                    </p>

                    <p>
                        <strong>4- YÜKSEK ÖĞRETİM KREDİ VE YURTLAR KURUMU GENEL MÜDÜRLÜĞÜNE BAĞLI YURTLARDAN VE KREDİLERDEN ÖNCELİKLE YARARLANMA HAKKI</strong>
                        Yüksek öğrenime devam eden öğrencilerden bütün şehit ve gazi çocukları Kuruma bağlı yurtlara öncelikli olarak yerleştirilmekte ve öğrenim süreleri boyunca yurtlarda yurt ücreti ve depozito alınmaksızın barındırılmakta olup, aynı zamanda yüksek öğrenim kredilerinden öncelikle istifade ettirilmektedirler.
                    </p>

                    <p>
                        <strong>5- YÜKSEK ÖĞRENİM KATKI PAYLARININ ALINMAMASI</strong>
                        Şehit olan personelin geride kalan çocuklarından yüksek öğrenim kurumlarında öğrenim görenlerden yüksek öğretim katkı payları alınmamaktadır.
                    </p>

                    <p>
                        <strong>6- ŞEHİT EŞİ ÖĞRETMENLERİN ATAMANLARINDA KOLAYLIK</strong>
                        3797 Sayılı kanunun 61 nci maddesi uyarınca, Şehit eşi öğretmenlerin yer değiştirme suretiyle atanmalarında hizmet puanı ve sıra esası uygulanmamaktadır.
                    </p>

                    <p>
                        <strong>7- İLKÖĞRETİM OKULLARINA ÖNCELİKLİ KAYIT VE NAKİLLERDE KOLAYLIK</strong>
                        Milli Eğitim Bakanlığınca çıkarılan 2007/45 sayılı Genelge ile, İlköğretim kurumlarına kaydedilecek veya okullar arasında nakledilecek şehit çocuklarına, talepleri halinde istedikleri okullarda öğrenim görebilme imkanı sağlanmıştır.
                    </p>

                    <p>
                        <strong>8- VAKIFLAR GENEL MÜDÜRLÜĞÜNCE ŞEHİT ÇOCUKLARINA BURS VERİLMESİ</strong>
                        Genel Müdürlükçe sağlanacak burstan faydalanmak isteyen öğrenciler, öğrenciler öğretim yılı kayıtlarının başladığı tarihten itibaren bir ay içerisinde Bölge Müdürlüklerine başvururlar.
                    </p>

                    <p>
                        <strong>9- GENÇLİK VE SPOR GENEL MÜDÜRLÜĞÜNE AİT TESİSLERDE YAPILACAK SPOR MÜSABAKALARINA GİRMEDE KOLAYLIK</strong>
                        Kullanım hakkı Gençlik ve Spor Genel Müdürlüğüne ait olan tesislerde yapılacak spor müsabakalarında, Türkiye Cumhuriyeti Emekli Sandığı Genel Müdürlüğünce kendilerine “Gazi Tanıtım Kartı” verilen harp ve vazife malulü gaziler için özel yer tahsisi yapılmaktadır.
                    </p>


                </>

            )
        },
        {
            id: 6,
            title: 'Türk Silahlı Kuvvetlerince Sağlanan Haklar',
            details: (
                <>
                    <p><strong>Ölüm Yardımı ve Harcırah Ödemesi</strong></p>
                    <p>
                        926 sayılı TSK Personel Kanununun 177'nci maddesi ile 3269 sayılı Uzman Erbaş Kanununun 16'ncı maddesi gereğince, şehit personelin (subay, astsubay, uzman jandarma, uzman erbaş) varislerine en yüksek devlet memuru aylığının (ek gösterge dahil) iki katı tutarında görevli olduğu Birlik K.lığınca “Ölüm Yardım Ödeneği” ödenir. Subay, astsubay, uzman jandarmaların herhangi bir nedenle hayatını kaybetmesi halinde; görevli oldukları Birlik K.lığınca; 926 sayılı TSK Personel Kanunu hükümleri gereği en yüksek devlet memuru aylığının (ek gösterge dahil) iki katı tutarında, uzman erbaşların hayatını kaybetmesi halinde ise 3269 sayılı Uzman Erbaş Kanununa göre kendi maaşlarının iki katı tutarında ölüm yardımı yapılır. Ayrıca hayatını kaybeden TSK personelinin ailesine ikamet yerlerine kadar geçici görev yolluğu (harcırah) ödenir.
                    </p>
                    <p><strong>İlk Destek Yardımı</strong></p>
                    <p>
                        İlgili Kuvvet Komutanlığınca şehitlerin (Erbaş/Er) ailelerine acil ve öncelikli ihtiyaçlarını karşılamaya yönelik olarak ilk destek yardımı yapılır. Bu yardım evli ise şehidin eşine, bekar ise anne/babasına, yoksa cenazesini teslim alan kardeşlerinden ya da varislerinden birine ödenir.
                    </p>
                    <p><strong>Bayrak Verilmesi</strong></p>
                    <p>
                        TSK Tören Yönergesi uyarınca; şehitlerin tabutları üzerine sarılan bayraklar özel kutusu içerisinde, eşi, en büyük erkek evladından başlayarak çocukları, baba veya annesi, sırasına göre kanuni mirasçılarına verilir.
                    </p>
                    <p><strong>Şehit Personelin Şahsi Tabancalarının Varislerine Devri</strong></p>
                    <p>
                        6136 sayılı Ateşli Silahlar ve Bıçaklar ile Diğer Aletler Hakkında Kanun gereği, şehit subay, astsubay, uzman jandarma ve uzman erbaşların şahsi tabancaları varislerine devredilebilir. Bu maksatla; tabancayı adına tescil ettirecek varis, veraset ilamı ve 18 yaşından büyük varislerden alacağı noterden onaylı feragatname ile ilgili Kuv.K.lığı Lojistik Başkanlığına müracaat eder. Buradan verilecek menşei belgesi alındıktan sonra, ruhsat için Jandarma Bölgesinde ikamet edenlerin İl Jandarma K.lıklarına, diğerlerinin ise İl Emniyet Müdürlüklerine başvurmaları gerekmektedir.
                    </p>
                    <p><strong>Göreve Devam Etme, Yara İşareti Takılması, Malul Gazi Şerit Rozeti Verilmesi</strong></p>
                    <p>
                        Maluliyeti hakkında kesin işlemli sağlık raporu bulunan ve kendi isteğiyle göreve devam etmek için başvuranlardan, TSK Sağlık Yeteneği Yönetmeliğine göre göreve devam etmesi uygun olduğu Gnkur.Bşk.lığınca onaylananlar, TSK'nde uygun görev yerlerinde mevcut statüleri ile istihdam edilir. İç güvenlik görevlerinde veya kaçakçılığın men ve takibi ile hudut emniyet hizmetlerinde fiilen çatışmalar sonucu yararlananlardan; yaralandığını onaylanmak suretiyle belgeleyen ve göreve devam eden personel yara işareti taşır. Türkiye Cumhuriyeti Devleti sınırlarını korumak ve güvenliğini sağlamak görevi ile harpte veya devletin bekasını hedef alan terör örgütlerine karşı yurtiçi ve yurtdışı mücadelede, her çeşit düşman ve terörist silahlarının tesiri ile harp bölgesindeki harekat ve hizmetlerin sebep ve tesiri ile yaralanarak malul olan ve göreve devam eden personele, “Malul Gazi Şerit Rozeti” verilir.
                    </p>
                    <p><strong>Malul Gazi Rozeti Verilmesi</strong></p>
                    <p>
                        Kendilerine aylık bağlanan malul gazilere, sadece sivil kıyafetleri ile kullanılmak üzere, Genelkurmay Başkanlığınca hazırlanan “Malul Gazi Rozeti” bağlı bulunduğu Kuv.K.lığı tarafından verilir.
                    </p>
                    <p><strong>Mehmetçik Yaşam Sigortası Ödemesi</strong></p>
                    <p>
                        TSK'nde görevli personelin düşük bir miktar karşılığı sigorta yaptırmaları halinde; hizmette bulundukları esnada her ne sebeple ve suretle olursa olsun vefat etmeleri ya da “Askerliğe Elverişli Değildir” raporu alarak malul olmaları halinde eş, çocuk, ana ve babalarına ya da kendilerine ilgili sigorta şirketi tarafından belirlenen miktarlarda ödeme yapılır.
                    </p>
                    <p><strong>TSK Mehmetçik Vakfı, TSK Dayanışma Vakfı ve Ordu Yardımlaşma Kurumu (OYAK) Üye Yardımları:</strong></p>
                    <p><strong>(1) TSK Mehmetçik Vakfı Yardımları:</strong></p>
                    <p>
                        TSK Mehmetçik Vakfı Yönetmeliği ile belirlenmiş durumlarda hayatını kaybetmiş Erbaş/Erlerin varislerine Vakıf tarafından aşağıdaki yardımlar yapılır.
                    </p>
                    <p><strong>(a) Bir Defaya Mahsus Yapılan Yardımlar:</strong></p>
                    <p>
                        (I) Şehit Olan Veya Herhangi Bir Nedenle Hayatını Kaybeden Erbaş/Erlere Yapılan Ölüm Yardımı: Şehit olan veya herhangi bir nedenle hayatını kaybeden erbaş/erlerin varislerine yapılır. Ödemenin varisler arasında paylaşımı ilgili yönetmelikte belirlenmiş esaslara göre yapılır. (II) Malul Gazi Erbaş/Erler ile Engelli Erbaş/Erlere, Maluliyet Dereceleri Esas Alınmak Suretiyle Yapılan Maluliyet Yardımı: Malul gazi erbaş/erlere, maluliyet derecesine göre yapılır. Yardımın yapılabilmesi için malul gazi erbaş/er hayatta iken başvurulması gerekir. (III) Sürekli Bakım Yardımı Almakta İken Vefat Eden Malul Gazi ve Engelli Erbaş/Erler İçin Yapılan Ölüm Yardımı: Vakıftan “Sürekli Bakım Yardımı” almakta iken vefat eden malul gazi erbaş/erler için yapılır. (IV) Şehit Olan veya Herhangi Bir Nedenle Hayatını Kaybeden Erbaş/Erin Ölüm Tarihinde, Malul Gazi ve Engelli Erbaş/Erin ise Olay Tarihinde Eşi Hamile ise Doğum Yaptığında Ödenen Doğum Yardımı: Vakıftan “Ölüm Yardımı” almaya hak kazanan Erbaş/Erin hayatını kaybettiği tarihte ana rahminde olup da sağ doğan çocuğu için bir defaya mahsus olmak üzere çocuğun annesine, annesi bulunmuyorsa vasisine doğum yardımı yapılır. (V) Bakım ve Öğrenim Yardımı Almakta Olan Çocuğun Vefatı Halinde Ödenen Ölüm Yardımı: Vakıftan “Bakım ve Öğrenim Yardımı” almaya hak kazanmış her bir çocuğun ölümü halinde; çocuğun annesine, annesi bulunmuyorsa vasisine çocuk ölüm yardımı yapılır.
                    </p>
                    <p><strong>(b) Sürekli veya Belli Bir Süre Dahilinde Yapılan Yardımlar:</strong></p>
                    <p>
                        (I) Malul Gazi ve Engelli Bakım Yardımı: Vakıftan “Maluliyet Yardımı” almayı hak eden ve maluliyeti 1'inci, 2'inci, 3'üncü ve 4'üncü derece olan erbaş/erlere hayatları boyunca üç aylık dönemler halinde yapılır. (II) Bakım ve Öğrenim Yardımı: Vakıftan “Ölüm Yardımı” ve “Maluliyet Yardımı” hak eden erbaş/erlerin; ölüm tarihinden önce doğmuş çocukları ile bu tarihte ana rahminde olup, müteakiben doğan çocukları için ilgili yönetmelikte belirlenmiş esaslar dahilinde (her üç ayda bir) yardım yapılır.
                    </p>
                    <p><strong>(2) TSK Dayanışma Vakfı Yardımları:</strong></p>
                    <p>
                        Şehit olan personelin varislerine, yönetmelikte belirlenmiş esaslar çerçevesinde yardım yapılır.
                    </p>
                    <p><strong>(3) Ordu Yardımlaşma Kurumu (OYAK) Üye Yardımları:</strong></p>
                    <p>
                        Şehit olan personelin varislerine, OYAK Yönetim Kurulu kararı ile belirlenen esaslar çerçevesinde yardım yapılır.
                    </p>
                    <p><strong>ç. Şehit/Malul Çocuklarının Askeri Okullara Öncelikle Alınması</strong></p>
                    <p>
                        TSK bünyesinde görev yapmakta iken şehit, malul/muharip gazi olan personelin çocuklarına, askeri okullara girişte, giriş koşullarını sağlamaları halinde ek puan veya kontenjan verilerek öncelik sağlanmaktadır. Bu haktan yararlanmak isteyenlerin; alım yapılacak yılın başvuru kılavuzlarında belirtildiği şekilde hareket ederek; ilgili Askeri Okul K.lıklarına başvurmaları gerekmektedir.
                    </p>
                    <p><strong>d. TSK Akıllı Kartı Verilmesi</strong></p>
                    <p>
                        TSK Akıllı Kart Yönergesi uyarınca; vazife/harp malullerinin kendileri ile vazife/harp malulü olarak şehit olan personele (subay, astsubay, uzman jandarma ve uzman erbaşlara) ve bunların hak sahibi aile fertlerine TSK Personel Akıllı Kartı verilir. TSK Akıllı Kartlarında, malul gazi olan personelin statüsünü gösteren bölüme “Malul Gazi” ifadesi ile sınıf ve rütbesini gösteren bölüme ayrıldığı tarihteki rütbesi yazılır. Malul Gazilerin hak sahibi aile fertlerine verilecek olan kartlarda da aynı ibare yer alır. SGK tarafından verilen Ücretsiz Seyahat Kartının ibrazı halinde kart ücreti alınmaz. Vazife malullüğü gerektiren sebeplerle şehit olan erbaş/erlerin hak sahibi aile fertlerine Askeri Hastanelere Giriş Kartı verilir. TSK Akıllı Kartı ile ilgili diğer işlemler için, ikamet edilen yerde bulunan Askerlik Şubesi Başkanlığına başvurulması gerekir.
                    </p>
                    <p><strong>e. TSK Özel/Yerel Eğitim Merkezlerinden Yararlanma</strong></p>
                    <p>
                        Şehit/malul subay, astsubay ve uzman jandarmaların eş ve çocuklarına özel/yerel eğitim merkezlerinden yararlanmada (maluller için malullük derecelerine göre) ek puan verilmek suretiyle avantaj sağlanır. Bu merkezlerden yararlanmak isteyenlerin bağlı bulundukları Kuv.K.lığına dilekçe ile başvurmaları gerekir. TSK Akıllı Kartına sahip şehit subay, yedek subay, astsubay ve uzman jandarmaların aile fertleri ile TSK Akıllı Kartına sahip malul personel ile aile fertlerinden günübirlik giriş ücreti ve otopark ücreti alınmaz.
                    </p>
                    <p><strong>f. TSK Rehabilitasyon ve Bakım Merkezinden Yararlanma</strong></p>
                    <p>
                        TSK Rehabilitasyon ve Bakım Merkezinde (Lodumlu-Ankara) başta malul gaziler olmak üzere hak sahibi tüm TSK personeli ve ailelerine hizmet verilmektedir. Merkezde malul gazi personelin, her türlü ortopedik (eklem hareket kısıtlıkları, ampüteler, ortez ve protez uygulamaları, sekel bırakan nitelikte romatizmal hastalıklar, yürüyüş bozuklukları ve diğer ağrılı kas iskelet sistemi hastalıkları vs.) ve nörolojik (omurilik yaralanması, beyin hasarı, inme, sinir sistemi hastalıkları vs.) rahatsızlıklarının rehabilitasyonu yapılmaktadır. Ankara dışından gelmiş hastalar ve refakatçileri için Merkez bünyesinde 50 Yataklı Otel hizmet vermektedir. Bakıma muhtaç ve kimsesiz malul gazilerden; bakıma muhtaçlığı GATA ve/veya TSK Sağlık Kurulu Raporuyla belgelenen, kimsesizliği onaylanan ve Merkez Başkanlığınca uygun görülen malul gaziler, tesisin 50 Yataklı Bakım Merkezi bölümünden sürekli olarak yararlanabilir.
                    </p>
                    <p><strong>g. TSK Ali Çetinkaya İlk Kurşun Rehabilitasyon Merkezinden Yararlanma</strong></p>
                    <p>
                        Balıkesir ili, Ayvalık ilçesinde bulunan TSK Ali Çetinkaya İlk Kurşun Rehabilitasyon Merkezinden, TSK mensubu şehitlerin eşleri, çocukları, anne/babaları ile anne/babanın refakatinde bulunan şehit personelin 18 yaşını doldurmamış erkek kardeşleri ve evlenmemiş kız kardeşleri birlikte yararlanabilirler. Ayrıca malul gaziler evli ise eşi ve çocukları, bekar ise anne, baba ve bekar kardeşi ile birlikte yararlanabilirler. Aksi taktirde yer tahsisi yapılmaz. Tesisten 02 Mayıs-27 Ekim tarihleri arasında 10 (on) günlük, diğer tarihler arasında ise 13 (on üç) günlük dönemler halinde yararlanılır. Tesisten yararlanan şehit erbaş/er aileleri ile malul gazi erbaş/erler ve yakınlarının gidiş-dönüş yol paraları, tesisteki tabldot usulü yemek paraları (sabah/öğle/akşam) TSK Mehmetçik Vakfı bütçesinden, TSK Ali Çetinkaya İlk Kurşun Rehabilitasyon Merkez K.lığınca karşılanır. Şehit subay, astsubay, uzman jandarma ve uzman çavuşların aile fertlerinin, yol ve yemek ücretleri kendileri tarafından karşılanır. Tesisten yararlanan hak sahiplerinden yatak ücreti alınmaz. Merkezden yararlanmak isteyenlerin “Kara Kuvvetleri Komutanlığı Personel İşlem Daire Başkanlığı Yücetepe/ANKARA” adresine dilekçe ile başvurmaları gerekmektedir. Dilekçe örnekleri, Askerlik Şubesi Başkanlıkları ile Askeri Birlik Komutanlıkları ve www.kkk.tsk.tr/GenelKonular/Sehit/yarali adresinden temin edilebilir.
                    </p>
                    <p><strong>ğ. Askerlik Hizmetinden Muafiyet</strong></p>
                    <p>
                        1111 sayılı Askerlik Kanunu hükümlerine göre askerlik hizmetini yerine getirmekte iken hayatını kaybedenler ile maluliyet aylığı almasını gerektirecek şekilde malul olanların anne/babasının müşterek olarak talep ettiği veya anne/babasından biri ölmüş ise sağ olanın talep ettiği kardeşlerinden biri, istekli olmadıkça silah altına alınmaz veya silah altında ise terhis edilir. Anne/babanın müştereken anlaşamadıkları veya her ikisinin de ölmüş olması durumunda; öncelikle silah altında olan kardeşi var ise istekli olması halinde terhis edilir, silah altında olan kardeşi yok ise ya da silah altında olan kardeşi terhis olmak istemez ise askerlik hizmet sırası gelen ilk kardeş istekli olmadıkça silah altına alınmaz. Ayrıca yine 1111 sayılı Kanun hükümlerine göre; 3713 sayılı Terörle Mücadele Kanunu kapsamında hayatını kaybeden yükümlülerin kendilerinden olma erkek çocukları ile aynı anne/babadan olan kardeşlerinin tamamı, istekli olmadıkça silah altına alınmaz ve silah altındakiler istekleri halinde terhis edilir. Bu haktan 1076 sayılı Kanuna tabi yedek subaylar ile, 1111 sayılı Kanuna tabi erbaş/erlerin çocukları ve kardeşleri yararlanır. Bu haktan yararlanmak için hak sahiplerinin kayıtlı bulundukları Askerlik Şubesi Başkanlığına dilekçe ile başvurmaları gerekmektedir.
                    </p>
                    <p><strong>h. İkametine Yakın Yerde Askerlik Hizmeti Yapma</strong></p>
                    <p>
                        TSK Erbaş ve Er İşlemleri Yönergesi uyarınca; askerlik hizmetini yapmakta iken şehit/malul olan kamu personelinin askerlik hizmeti yapacak kardeş ve çocuklarının dağıtımları mümkün olduğu kadar ikamet ettikleri yere yakın birlik, kurum ve karargahlara yapılır. Bu haktan yararlanmak için; hak sahibi veya ailesinin öncelikle Askerlik Şubesi Başkanlığına, bu başvuru için geç kalınmışsa, eğitim birliğine veya oğlun/kardeşin mensup olduğu Kuv.K.lığına dilekçe, vukuatlı nüfus kayıt örneği ve şehit belgesi ile başvurması gerekmektedir. Bu talep askerliğin her safhasında yapılabilir.
                    </p>


                </>
            )

        },
        {
            id: 7,
            title: 'Yeni Sosyal Güvenlik Sisteminden Dolayı Sağlanan Haklar',
            details: (
                <>
                    <p><strong>1- ÇALIŞMAYA DEVAM ETME HAKLARI</strong></p>
                    <p>
                        31.5.2006 tarihinde yürürlüğe girerek Ekim 2008 tarihinden itibaren uygulamaya başlanılan 5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası Kanununa göre, aşağıdaki haklar sağlanmıştır.
                    </p>
                    <p>
                        Terörle Mücadele Kanunu kapsamında vazife malûllüğü aylığı bağlanmış (er ve erbaşlar dahil) malûllerden, çalışmaya başlayanların aylıkları hiçbir şekilde kesilmez.
                    </p>
                    <p>
                        Ayrıca, terörle mücadele dışındaki vazife malûllüğü aylığı bağlanmış malûllerle harp malullerinin, işçi ve bağımsız çalışan olarak işe başlaması hallerinde sosyal güvenlik destek primi ile aylıkları kesilmez. Bunlardan işçi ve bağımsız çalışanlara kısa vadeli sigorta kolları, memur olarak çalışanlara ise uzun vadeli sigorta kollarına ait hükümler uygulanır.
                    </p>

                    <p><strong>2- SAĞLIK HAKLARI</strong></p>

                    <p><strong>1- Katılım payı alınmayacaklar</strong></p>
                    <p>
                        3292 sayılı Vatani Hizmet Tertibi Aylıklarının Bağlanması Hakkında Kanun hükümlerine göre aylık alan kişilerden ayakta tedavide hekim ve diş hekimi muayenesinden, ayakta tedavide sağlanan ilaçlar ve tıbbi malzemelerden katılım payı alınmayacaktır.
                    </p>
                    <p>
                        Ayrıca;
                    </p>
                    <p>
                        a) 1005 sayılı İstiklal Madalyası Verilmiş Bulunanlara Vatani Hizmet Tertibinden Şeref Aylığı Bağlanması Hakkında Kanun hükümlerine göre şeref aylığı alan kişiler ile bunların eşlerinden,
                    </p>
                    <p>
                        b) 2330 sayılı Nakdi Tazminat ve Aylık Bağlanması Hakkında Kanun hükümlerine göre aylık alan kişilerden,
                    </p>
                    <p>
                        ç) Harp malûllüğü aylığı alanlar ile Terörle Mücadele Kanunu kapsamında aylık alanlardan,
                    </p>
                    <p>
                        d) Vazife malûllerinden;
                    </p>
                    <p>
                        ayakta tedavide hekim ve diş hekimi muayenesinden, ayakta tedavide sağlanan ilaçlar ve tıbbi malzemeler ile yardımcı üreme yönteminden katılım payı alınmayacaktır.
                    </p>

                    <p><strong>2- İlave ücret ile ilgili olarak;</strong></p>
                    <p>
                        Kurumla sözleşmeli resmi sağlık kurum ve kuruluşları tarafından sevk edilmeleri koşuluyla;
                    </p>
                    <p>
                        a) 1005 ve 2330 sayılı Kanunlar ile,
                    </p>
                    <p>
                        b) Harp malûllüğü aylığı alanlar ile Terörle Mücadele Kanunu kapsamında aylık alanlardan ve bunların bakmakla yükümlü olduğu kişilerden otelcilik hizmetleri ile istisnai sağlık hizmetleri hariç olmak üzere ilave ücret alınamayacaktır.
                    </p>

                    <p><strong>3- Tıbbi malzeme temini ile ilgili olarak;</strong></p>
                    <p>
                        3713 sayılı Kanuna göre aylık bağlanmış malûller ile aynı Kanun kapsamına giren olaylar sebebiyle vazife malûllüğü aylığı alan er ve erbaşların sağlık kurulu raporuyla ihtiyaç duydukları her türlü ortez/protez ve diğer iyileştirici araç/gereçlerden fark alınmaksızın ve kısıtlama (süre vb.) getirilmeksizin Kurumca karşılanacaktır.
                    </p>
                </>
            )

        },
        {
            id: 8,
            title: 'Şehit Yakını ve Gazilerimize İş Temini',
            details: (
                <>
                    <p>
                        Terör nedeni ve etkisiyle şehit olan kamu görevlileri, er-erbaş ile geçici ve gönüllü köy korucularının eş, çocuk veya kardeşlerinden birisine, 3713 sayılı Terörle Mücadele Kanununun Ek 1 inci maddesi gereğince İçişleri Bakanlığı Personel Genel Müdürlüğü koordinasyonunda kamu kurum ve kuruluşlarında iş imkanı sağlanmaktadır.
                        Konuyla ilgili açıklamalar aşağıdaki şekildedir:
                    </p>
                    <p><strong>Şehit Yakınlarının Hak Sahipliği:</strong></p>
                    <p>
                        İlk hak sahibi şehidin eşidir. Eşinin olmaması veya talepte bulunmaması halinde çocuklarından biri, çocuğu yoksa veya yaşları itibariyle çalışamayacak durumda ise, çocuğun kanuni vasisinin iş hakkından feragat etmesi üzerine şehit kardeşlerinden biri olmak üzere, şehit ailesinden sadece bir kişi bu haktan yararlanabilir. Kamu kurum ve kuruluşunda memur, sözleşmeli personel veya sürekli işçi kadrosunda çalışan hak sahipleri iş talebinde bulunamaz.
                        Çocuk veya kardeşlerden öncelikle yaşı büyük olanın işe alınması esastır. (Kanunda belirtilen öncelik sırası dikkate alınmak ve işe gireceği kurumun üst yaş sınırını aşmamak kaydıyla) Şehitlik olayının olduğu tarihte 18 yaşını doldurmamış çocuk ve kardeşlerin müracaat süresi, 18 yaşını doldurdukları tarihten itibaren başlar.
                    </p>
                    <p><strong>Malul ve Malul Yakınlarının Hak Sahipliği:</strong></p>
                    <p>
                        Terör nedeni ve etkisiyle çalışabilecek durumda malul olan kamu görevlisi, er-erbaş ile geçici ve gönüllü köy korucusunun kendisi, çalışamayacak derecede malul ise, iş hakkından hak sahiplerinden birisi lehine feragat etmesi üzerine eş, çocuk veya kardeşlerinden birisi iş hakkından faydalandırılır.
                    </p>
                    <p><strong>Başvuru Süresi:</strong></p>
                    <p>
                        İş hakkından yararlanmak isteyen hak sahipleri şehitlik olayının meydana geldiği tarihten, maluller ise malul maaşı bağlandığı tarihten itibaren 15 yıl içerisinde başvuruda bulunmak zorundadır. Bu süre içerisinde yapılmayan başvurular dikkate alınmaz. Ancak, şehitlik ve malullük olayının olduğu tarihte 18 yaşını doldurmamış çocuk ve kardeşlerin müracaat süresi 18 yaşını doldurdukları tarihten itibaren başlar. 3713 sayılı Terörle Mücadele Kanununun Ek 1 nci maddesi uyarınca yürürlüğe konulan yönetmeliğin yürürlüğe girdiği 29.03.1996 tarihinden önce olan şehit ve malullük olayları için, müracaat süresi yönetmeliğin yürürlüğe girdiği tarihten itibaren başlar.
                    </p>
                    <p><strong>Müracaat Esasları:</strong></p>
                    <p>
                        Hak sahibi, iş istek formunu doldurduktan sonra; 1- Öğrenim belgesinin tasdikli bir örneği (son bitirdiği okul diploması veya çıkış belgesi) 2- Sabıka kayıt belgesi 3- Malule ait sağlık kurulu raporunun örneği (malul müracaatlarında) 4- Malul maaşı aldığını kanıtlayan resmi yazı veya tasdikli aylık bağlama kararı (malul ve malul yakını müracaatlarında) 5- Diğer hak sahiplerinden noter huzurunda alınacak feragat dilekçesi ile şehit veya malulün T.C. Kimlik numarası ile birlikte, ikamet ettiği yerdeki kaymakamlığa veya valiliğe müracaat eder. İş için müracaat eden kişi ilk hak sahibi değilse, kendisinden önceki hak sahiplerinden alacağı “3713 sayılı Kanun gereği tanınan iş hakkından” işe girecek kişi lehine feragat ettiğine dair noter tasdikli beyanları müracaat evraklarına ekleyecektir. Şehit eşinin başvurusu halinde diğer aile fertlerinden feragat beyanı alınmaz.
                    </p>
                    <p>
                        Yukarıdaki esaslara göre hazırlanan müracaat dosyası, şehitlik ve malullük olayının olduğu İl veya İlçe Hak Sahibi Tespit Komisyonu tarafından kanun kapsamına girdiğinin belirlenmesine müteakip, İçişleri Bakanlığı Personel Genel Müdürlüğüne gönderilir. Hak sahibi, burada yapılacak değerlendirme sonucu İçişleri Bakanlığı koordinasyonunda kamu kurum ve kuruluşunda işe yerleştirilir.
                    </p>
                </>
            )

        },
        {
            id: 9,
            title: 'Vatani Hizmet Tertibinden Sağlanan Haklar',
            details: (
                <>
                    <p><strong>1- ŞEREF AYLIKLARI</strong></p>
                    <p>
                        1005 sayılı İstiklal Madalyası Verilmiş Bulunanlara Vatani Hizmet Tertibinden Şeref Aylığı Bağlanması Hakkındaki Kanun gereğince, aşağıdaki sayılanlara aylık bağlanır:
                    </p>
                    <ul>
                        <li>Milli mücadeleye iştirak eden ve bu sebeple kendilerine İstiklal Madalyası verilmiş bulunan Türk vatandaşlarına,</li>
                        <li>Türk Tugayının 1950 yılında Kore'ye ayak bastığı Ekim ayından başlamak ve 1953 yılı Pan-Munjon Ateşkes Anlaşmasına kadar Kore'de fiilen savaşa katılmış olan Türk vatandaşlarına,</li>
                        <li>Kıbrıs'ta 1974 yılında Temmuz 1. ve Ağustos 2. Barış Harekatına fiilen görev alarak katılmış olan Türk vatandaşlarına.</li>
                    </ul>
                    <p>
                        Hayatta bulundukları sürece, vatani hizmet tertibinden 5750 gösterge rakamının her yıl Bütçe Kanunu ile tespit edilen memur maaş katsayısı ile çarpılmasından bulunacak miktarda aylık bağlanır.
                    </p>
                    <p><strong>- Eşlere Aylık Bağlanması</strong></p>
                    <p>
                        İstiklal Savaşı, Kore Savaşı ve Kıbrıs Savaşına katılanların ölümleri halinde, verilmekte olan aylık, dul eşe % 75 oranında bağlanır.
                    </p>
                    <p><strong>- Çalışmayan, Aylık ve Geliri Olmayanlara Verilen Aylığın Yükseltilmesi</strong></p>
                    <p>
                        Aylığa hak kazanan İstiklal Savaşı, Kore Savaşı ve Kıbrıs Savaşı gazilerinin, herhangi bir sosyal güvenlik kurumundan gelir veya aylık almaması ve çalışmaması halinde kendilerine 30 günlük net asgarî ücret tutarı ödenir. Gazilerin dul eşlerinin herhangi bir sosyal güvenlik kurumundan gelir veya aylık almaması ve çalışmaması halinde ise 30 günlük net asgarî ücretin % 75'i ödenir.
                    </p>
                    <p><strong>2- VATANİ HİZMET TERTİBİNDEN EŞ, ANNE ve ÇOCUKLARA TANINAN SEYAHAT HAKLARI</strong></p>
                    <p>
                        İstiklal Savaşı, Kore Savaşı ve Kıbrıs Savaşı Gazilerinin kendileri ve eşleri ile şehitlerin anne ve babaları, Devlet Demiryollarının yurtiçi hatlarında, Denizyolları şehir hatlarında, belediyelere, belediyeler tarafından kurulan şirketlere ya da belediyeler tarafından yetki verilen özel şahıs veya şirketlere ait şehir içi toplu taşıma araçlarında ücretsiz olarak seyahat ederler.
                    </p>
                    <p>
                        İstiklal Savaşı, Kore Savaşı ve Kıbrıs Savaşı Gazilerinin dul eşleri ile şehitlerin, sosyal güvenlik kuruluşlarından yetim aylığı dışında aylık veya gelir almayan ve bu kuruluşlara tâbi olarak çalışmayan çocukları, şehit olan anne ve babalarından dolayı yetim aylığı aldıkları müddetçe, aynı haklardan yararlanırlar.
                    </p>
                    <p><strong>3- VATANİ HİZMET TERTİBİNDEN SAĞLIK HAKLARI</strong></p>
                    <p>
                        İstiklal Savaşı, Kore Savaşı ve Kıbrıs Savaşı Gazileri ile bunların aylık bağlanan eşlerinin resmî sağlık kurum ve kuruluşlarında veya bu kurum ve kuruluşlarca sevk edildikleri özel sağlık kurum ve kuruluşlarında görecekleri tedavilere ilişkin giderler ile ilaç giderleri, herhangi bir katılım payı veya fark alınmaksızın karşılanır.
                    </p>
                    <p>
                        Bu kapsamda verilen sağlık karneleri daha önceleri askerlik şubeleri tarafından verilmekte iken, yapılan yeni düzenleme ile Sosyal Güvenlik Kurumu tarafından verilmektedir.
                    </p>
                    <p><strong>4- VATANİ HİZMET TERTİBİNDEN AYLIK ALANLARA SU TÜKETİMİNDE İNDİRİM</strong></p>
                    <p>
                        İstiklal Savaşı, Kore Savaşı ve Kıbrıs Savaşı Gazileri ile bunların aylık bağlanan eşlerinden; belediyelerce tahakkuk ettirilecek su ücreti % 50'den az indirim içermemek üzere belediye meclisleri tarafından belirlenecek tarife üzerinden alınır.
                    </p>
                    <p>
                        Bu uygulamadan yararlanabilmek için ikamet edilen yerin Belediye Başkanlığına istenilen belgelerle birlikte başvurulması gerekmektedir.
                    </p>
                </>
            )

        },
        {
            id: 10,
            title: 'Nakdi Tazminat Sisteminden Dolayı Sağlanan Haklar',
            details:
                (
                    <>
                        <p><strong>1- NAKDİ TAZMİNAT</strong></p>
                        <p>
                            2330 sayılı Nakdi Tazminat ve Aylık Bağlanması Hakkındaki Kanun gereğince, “İç güvenlik ve asayişin korunması veya kaçakçılığın men, takip ve tahkiki konularında görevlendirilmeleri sonucu”nda malul duruma düşen ve yaralananlar ve bunlardan ölmüş olanların kanuni mirasçılarına tazminat ödenir.
                        </p>
                        <p>
                            Bu Tazminat, En Yüksek Devlet Memuru Aylığının:
                        </p>
                        <ul>
                            <li>a) Ölenlerin kanuni mirasçılarına 100 katı tutarında,</li>
                            <li>b) Bakıma muhtaç özürlü hale gelenlere 200 katı tutarında,</li>
                            <li>c) Ağır özürlü hale gelenlere 75 katı tutarında,</li>
                            <li>d) Hafif özürlü hale gelenlere 50 katı tutarında,</li>
                            <li>e) Yaralananlara, 20 katını geçmemek üzere, ödenir.</li>
                        </ul>
                        <p>
                            Tazminattan yararlanmak isteyenler bağlı oldukları kurumlarına istenilen belgelerle birlikte başvururlar.
                        </p>
                        <p>
                            GKK'larının nakdi tazminat işlemleri Valilikler ve İçişleri Bakanlığınca yürütülmektedir.
                        </p>

                        <p><strong>2- YURT DIŞI NAKDİ TAZMİNAT</strong></p>
                        <p>
                            2453 sayılı Yurt Dışında Görevli Personele Nakdi Tazminat Verilmesi ve Aylık Bağlanması Hakkında Kanun ve 2330 sayılı Nakdi Tazminat ve Aylık Bağlanması Hakkındaki Kanun gereğince,
                        </p>
                        <ul>
                            <li>a) Yurt dışı kamu personelinden; bu görevlerinden dolayı maruz kaldıkları tedhiş veya uğradıkları suikast sonucu,</li>
                            <li>b) Türkiye'nin taraf olduğu anlaşmalar uyarınca yabancı ülkelerde yapılan eğitim, tatbikat, manevra veya harekat sırasında, ölen veya özürlü hale gelenlere tazminat ödenir.</li>
                        </ul>
                        <p>
                            Bu personel ile bunların eş ve ikinci derece dahil kan ve birinci derece sıhri hısımlarından yurt dışında maruz kaldıkları tedhiş veya uğradıkları suikast neticesinde ölenlerin cenazelerinin nakil ve defin giderleri ile yaralanan veya sakat kalanların tedavi giderleri karşılanır.
                        </p>
                        <p>
                            Bu kapsamdaki olaylar neticesi ölen diğer Türk uyrukluların cenazelerinin nakil ve defin giderleri ayrıca karşılanır.
                        </p>

                        <p><strong>3- NAKDİ TAZMİNAT SİSTEMİ NEDENİYLE SSK VE BAĞ-KUR SİGORTALILARINA BAĞLANAN AYLIĞIN ARTIRILMASI</strong></p>
                        <p>
                            İç güvenlik ve asayişin korunması veya kaçakçılığın men, takip ve tahkiki konularında görevlendirilmeleri sonucunda, malul duruma düşen Sosyal Sigortalar Kurumu sigortalıları ile Bağ-Kur sigortalılarına bağlanan aylıklar % 25 oranında artırılır. Bunların ayrıca Nakdi tazminat, ek ödeme ile eğitim ve öğretim yardımı hakları bulunmaktadır.
                        </p>
                    </>

                )

        },
        {
            id: 11,
            title: 'Terörle Mücadele Sisteminden Dolayı Sağlanan Haklar',
            details:
                (
                    <>
                        <p><strong>1- İKRAMİYE:</strong></p>
                        <p>
                            3713 sayılı Terörle Mücadele Kanunu kapsamındaki görevler nedeniyle; başkasının yardım ve desteğine muhtaç olacak derecede malül olanlar ile ölenlerin dul ve yetimlerine, en yüksek devlet memuru aylığı üzerinden 30 yıl hizmet yapmış gibi emekli ikramiyesi ödenir.
                        </p>

                        <p><strong>2- KAMU KONUTLARINDAN YARARLANMA HAKKI:</strong></p>
                        <p>
                            Terörle Mücadele Kanunu kapsamındaki görevler nedeniyle malul duruma düşerek aylık bağlananlarla, bunlardan ölenlerin aylığa hak kazanan dul ve yetimleri, (Kamu Konutları Kanunu'nda gösterilen özel tahsisli konutlarda oturanlar hariç olmak üzere) bir yıl süreyle kamu konutlarından yararlanmaya devam ederler.
                        </p>

                        <p><strong>3- KİRA YARDIMI:</strong></p>
                        <p>
                            3713 sayılı Terörle Mücadele Kanunu kapsamındaki görevler nedeniyle:
                            <ul>
                                <li>Malul duruma düşerek aylık bağlananlarla, bunlardan ölenlerin aylığa hak kazanan dul ve yetimlerinden bir yıl süreyle kamu konutlarından yararlanmaya devam ettikten sonra ayrılanların,</li>
                                <li>Kamu konutundan yararlanmayanların,</li>
                                <li>Özel tahsisli konutlarda oturanların, istekleri halinde ikametgah olarak kullanacakları yurtiçindeki taşınmazın kira bedeli on yıl süre ile devletçe karşılanır. Yurtdışındaki özel tahsisli konutlarda oturanların yurtdışı kira bedelleri de bir yıl süre ile devletçe karşılanır.</li>
                            </ul>
                            Bu çerçevede hak sahiplerinin konut kiralamalarında uyulması gereken usul ve esaslar aşağıda belirtilmiştir:
                            <ol>
                                <li>Kanun kapsamında bulunan kişilere ödenecek kira bedeli için belli bir limit belirlenmeyecek, rayiç kira bedelinin tamamı ilgili kamu kurum ve kuruluşunun bütçesinden karşılanacaktır.</li>
                                <li>Kiralanacak konutun kullanım alanı (net) 120 m²'yi geçmeyecek, bu alanın tespitinde konut sahibi ile konutu kiralayan arasında yapılan sözleşme esas alınacaktır.</li>
                                <li>İkametgâh olarak kiralanacak konutlar lüks (sauna, jakuzi, dubleks, tripleks, şömine) olmayan konutlardan seçilecek, büyükşehir belediyelerinin bulunduğu illerde şehrin bulvar ve ana caddeleriyle iş merkezi yoğunluklu mahaller dışında olacaktır.</li>
                                <li>3'üncü maddede belirlenen kriterlere göre hak sahiplerine ödenecek kira bedeli, kiralamanın yapıldığı yerdeki belediye rayiçlerini geçmeyecektir. Bunun için hak sahibi şehit yakını kiralamanın yapıldığı yerdeki belediye başkanlığından, konutun rayiç bedelini gösterir bir belge alması gerekmektedir.</li>
                                <li>Yakıt, su, elektrik, apartman ve site aidatı gibi ödemeler kira kapsamında olmadığından kira bedeli dışında tutulacak ve oturanlar tarafından karşılanacaktır.</li>
                                <li>3713 sayılı Kanunun anılan hükmü çerçevesinde kira sözleşmeleri hak sahibi ile mal sahibi arasında yapılacak ve kiralamanın yapıldığı yerdeki kurum harcama yetkilisince onaylanacaktır.</li>
                            </ol>
                        </p>

                        <p><strong>4- BARINMA VE BAKIM HAKKI:</strong></p>
                        <p>
                            Yaşamak için gerekli hareketleri kendi kendine yapmaktan aciz ve muhtaç olan Terör malulleri, kamu kurum ve kuruluşlarında ya da özel yurtlar ile huzurevlerinde parasız olarak barındırılır.
                        </p>

                        <p><strong>5- TOPLU KONUT FONUNDAN FAİZSİZ KONUT KREDİSİ VERİLMESİ:</strong></p>
                        <p>
                            Toplu Konut Fonundan şehit ailelerine, malullere ve dul yetimlerine açılacak faizsiz konut kredisi yönetmeliği 21/09/1991 tarih ve 20998 sayılı Resmi Gazete'de yayımlanarak yürürlüğe girmiştir. Söz konusu yönetmelik kapsamında kredi kullandırılacak hak sahipleri aşağıdaki şekilde belirlenmiştir:
                            Öncelik sıralamasına göre:
                            <ul>
                                <li>Maaşa bağlanan dul eşi,</li>
                                <li>Eşi hayatta değilse veya evlenmişse maaşa bağlanan çocukları müştereken,</li>
                                <li>Bunlar bulunmadığı takdirde maaşa bağlanan bakmakla yükümlü olduğu baba veya anası, bu krediden yararlanabilir.</li>
                            </ul>
                        </p>

                        <p><strong>6- ÜCRETSİZ SEYAHAT HAKKI:</strong></p>
                        <p>
                            3713 sayılı Terörle Mücadele Kanunu kapsamındaki görevler nedeniyle malul duruma düşerek aylık bağlananlarla, bunlardan ölenlerin dul ve yetimlerine ücretsiz seyahat etme hakkı tanınmıştır. Maaş bağlanması ile birlikte “Ücretsiz Seyahat (Serbest) Kartı” hak sahiplerine ulaştırılır.
                        </p>

                        <p><strong>7- YURT DIŞI TEDAVİ HAKKI:</strong></p>
                        <p>
                            Memur ve kamu görevlilerinden terör eylemlerine muhatap olarak yaralanan, sakatlananlar ile ölenlerin dul ve yetimleri, Emekli Sandığınca verilen tanıtım kartlarıyla, kamu kurum ve kuruluşlarına ait bütün hastanelerde muayene ve tedavi edilirler.
                        </p>

                        <p><strong>8- BURS VERİLMESİ:</strong></p>
                        <p>
                            3713 sayılı Terörle Mücadele Kanununun 21/ı maddesi uyarınca terörle mücadeleden dolayı köyleri boşaltılan üniversite öğrencilerine ve ölenlerin çocuklarına yüksek öğrenimleri süresince Devletçe karşılıksız burs verilir.
                        </p>

                        <p><strong>9- TERÖRDEN ZARAR GÖREN DİĞER KİŞİLERE YARDIM:</strong></p>
                        <p>
                            Terör eylemlerinden dolayı yararlananların tedavileri Devlet tarafından yapılır. Zarar gören, can ve mal kaybına uğrayan vatandaşlara, Sosyal Yardımlaşma ve Dayanışmayı Teşvik Fonundan öncelikle yardım yapılır.
                        </p>
                    </>

                )

        },
        {
            id: 12,
            title: 'Emekli Sisteminden Doğan Haklar',
            details:
                (
                    <>
                        <p><strong>1-VAZİFE MALÜLLÜĞÜ AYLIĞI</strong></p>
                        <p>
                            5434 sayılı T.C. Emekli Sandığı Kanunu'na göre, gerek askerlik görevini yürütmekte iken, gerekse askeri ve sivil memuriyet niteliğinde hizmet yürütmekte iken “görevden kaynaklanan nedenlerle” malul duruma düşenlere “vazife malullüğü aylığı” bağlanır.
                        </p>
                        <p>
                            Aylık bağlanabilmesi için kişinin, görevi ile ilgili nedenlerden dolayı malül hale gelmesi gerekir. Malullük derecesi Kanunda 6 derecede sınıflandırılmakta olup, ölüm halinde birinci derece malullük esas alınarak aylık bağlanmaktadır. Ölüm halinde dul ve yetimleri bu haklardan yararlandırılır. Uygun şartların bulunması halinde yetim kapsamında, ana ve baba da bu aylıktan yararlandırılmaktadır.
                        </p>
                        <p>
                            Vazife malulü ya da vazife malulü ölmüşse onun dul ve yetimleri istenen belgelerle birlikte kendi kurumlarına ya da Sosyal Güvenlik Kurumu Kamu Görevlileri Emeklilik Dairesi Başkanlığı'na başvururlar. Başka yerden aylık alıp almadığına veya herhangi bir işte çalışıp çalışmadığına dair bireysel konulara ilişkin düzenlenmesi gereken belgeler (Kimlik Araştırma Belgesi vb.), aylık bağlanacak kişiler tarafından tanzim edilerek Sosyal Güvenlik Kurumuna gönderilir.
                        </p>

                        <p><strong>2-HARP MALÜLLÜĞÜ AYLIĞI</strong></p>
                        <p>
                            T.C. Emekli Sandığı Kanunu'na göre, gerek askerlik görevini yürütmekte iken ve gerekse askeri ve sivil memuriyet niteliğinde hizmet yürütmekte iken “harp etkileri sonucu” malul duruma düşenlere “harp malullüğü aylığı” bağlanır.
                        </p>
                        <p>
                            Kanunun ilgili maddelerinde harp malullerinin kimleri kapsadığı gösterilmiştir. Bu kapsama girenler, vazife malulü işlemlerinde olduğu gibi, istenen belgelerle birlikte kurumlarına müracaat ederler. Kurumları gerekli belgeleri tamamladıktan sonra dosyalarını Sosyal Güvenlik Kurumuna gönderirler.
                        </p>

                        <p><strong>3-EK ÖDEME (TÜTÜN İKRAMİYESİ)</strong></p>
                        <p>
                            Harp malullüğü aylığı bağlananlarla “olağanüstü hal, talim, tatbikat veya manevra sırasında görevin veya çeşitli harp silah ve vasıtalarının sebep ve tesiriyle vazife malûllüğü aylığı bağlananlar” a hayatta oldukları sürece, bunlardan ölenlerin dul ve yetimlerine aylık bağlanması halinde ise aylık aldıkları sürece ayrıca “ek ödeme” yapılır.
                        </p>
                        <p>
                            Kanun değişikliği yapılmadan önce ek ödemeler tütün satışlarından elde edilen kârın belli bir oranının harp ve vazife malullerinin dul ve yetimlerine dağıtımı şeklinde gerçekleştirilirken, daha sonra yapılan düzenlemelerle “ek ödeme” başlığı altında dağıtımına başlanmıştır.
                        </p>
                        <p>
                            Bu ödemeler, Sosyal Güvenlik Kurumu tarafından yılda bir kez olmak üzere, herhangi bir müracaat şartı aranmaksızın her yılın ilk üç ayı içerisinde hak sahiplerinin hesaplarına aktarılır.
                        </p>

                        <p><strong>4-İKRAMİYE ÖDENMESİ</strong></p>
                        <p>
                            2629 sayılı Uçuş, Paraşüt, Denizaltı, Dalgıç ve Kurbağa Adam Hizmetleri Tazminat Kanunu ve 3160 sayılı Emniyet Teşkilatı Uçuş Hizmetleri Tazminat Kanunu kapsamına giren personelden, uçuş, paraşüt, denizaltı, dalgıç ve kurbağa adam hizmetlerinin fiilen ifası sırasında;
                        </p>
                        <p>
                            - Yaşamını sürdürebilmek için gerekli hareketleri yapamayacak ve başkasının yardım ve desteğine muhtaç olarak derecede malul olanlar ile ölenlerin dul ve yetimlerine en yüksek Devlet memuru aylığı üzerinden,
                        </p>
                        <p>
                            - Diğer malullere ise mevcut aylıkları üzerinden 30 yıl hizmet yapmış gibi, emekli ikramiyesi ödenir.
                        </p>

                        <p><strong>5-VAZİFE VE HARP MALÜLLÜĞÜ AYLIĞI ALANLARIN EMEKLİLİK SİSTEMİNDEN KAYNAKLANAN DİĞER HAKLARI</strong></p>
                        <p>
                            - Er Vazife ve Er Harp Malullüğü Aylığı Alanların Başka Bir İşte Çalışırken Aylıklarının Devam Etmesi: Askerlik görevini yürütürken malul duruma düşerek vazife veya harp malullüğü aylığı bağlandıktan sonra, gerek memuriyet niteliğinde olsun, gerekse sigortalı nitelikte olsun, başka bir göreve girenlerin vazife veya harp malullüğü aylıkları kesilerek görevlerinden ayrıldıktan sonra yeniden bağlanır, bu görevden ayrılma sırasında ayrıca bir aylığın hak edilmesi halinde iki aylık birlikte ödenir.
                        </p>
                        <p>
                            Ayrıca, emekli aylığı almakta iken, askerlik görevine başlamasından sonra, askerlikten dolayı vazife veya harp malullüğü aylığına ayrıca hak kazananlara, her iki aylığı birlikte ödenir.
                        </p>
                        <p>
                            Yukarıda belirtilenlerin ölümleri halinde, dul ve yetimlerine iki aylık birden ödenir.
                        </p>
                        <p>
                            - Sigortalı Çalışma Halinde Yetim Aylıkları Kesilmez: Er Vazife Malullüğü ve Er Harp Malullüğünden doğan yetim aylığı almakta iken sigortalı olarak çalışılması halinde yetim aylığı kesilmez.
                        </p>
                        <p>
                            - Harp Malullüğü Zammının Devamlılığı: Harp malullüğü aylığı alanlardan, memuriyete başlayanların harp malullüğü zammı kesilmez.
                        </p>
                        <p>
                            - Barınma Hakkı: Yaşamak için gerekli hareketleri kendi kendine yapmaktan aciz ve muhtaç olan harp malulleri, parasız olarak barındırılır ve tedavileri yaptırılır. Yedirilmeleri için harcanacak paralar, bunların aylık ve harp malullüğü zamlarından kesilir. Bunun için öncelikle ilgili askerlik şubelerine müracaat etmeleri gereklidir.
                        </p>
                        <p>
                            - Yükseltme Hakları (Emsal Terfi Uygulaması) ve Bakım Yardımı: a) Harp malullüğü aylığı bağlananlar, b) İç güvenlik ve asayişin korunması veya kaçakçılığın men, takip ve tahkiki konularında görevlendirilmeleri sonucunda Vazife Malullüğü Aylığı bağlananlar, c) Terörle mücadele nedeniyle, eğitim, tatbikat ve manevralar ile birlik halinde intikaller sırasında, bu harekat ve hizmetlerin sebep ve etkileriyle malul olanlara, hayatlarını kaybedenlerin dul ve yetimlerine bağlanacak olan Harp Malullüğü ve Vazife Malullüğü Aylıkları, “ölen kişinin hayatta kalan emsallerine göre” yükseltilir.
                        </p>
                        <p>
                            Bu nedenlerle vazife malullüğü ya da harp malullüğü aylığı bağlananlardan hayatta kalan, ancak başkasının yardım ve desteği olmadan yaşamak için gereken hareketleri yapamayacak derecede malul olanlara, ayrıca asgari ücretin net tutarı kadar bir meblağ aylıklarıyla birlikte ödenir. Daha sonra bu kapsama girenler de aynı haktan faydalanır.
                        </p>
                        <p>
                            - Kesilmeyen Yetim Aylıkları: T.C. Emekli Sandığından yetim aylığı almakta iken, Harp Okulunda eğitime başlayan öğrencilerin yetim aylıkları kesilmez.
                        </p>
                    </>
                )

        },
        {
            id: 13,
            title: 'Şehit Aileleri ve Gazilerimize Sağlanan Diğer Haklar',
            details:
                (
                    <>
                        <p><strong>1. Meskende Tüketilen Elektrik Enerjisinde İndirim</strong></p>
                        <p>
                            Sosyal Güvenlik Kurumu Kamu Görevlileri Emeklilik İşlemleri Dairesi Başkanlığınca kendilerine maaş bağlanan şehit yakını ve gazilerimizin ikamet ettikleri konutta tükettikleri elektrik enerjisine %40 oranında indirim uygulanmaktadır.
                        </p>
                        <p>
                            Şehit yakını ve gazilerimiz, söz konusu indirimli elektrik tarifesinden yararlanabilmek için kendilerine maaş bağlandığını gösteren belgesi ve mesken elektrik abonesi için <a href="http://www.tedas.gov.tr">TEDAŞ</a> internet adresinde belirtilen belgelerle birlikte, bağlı bulundukları yerin İl Müdürlüğü veya İlçe İşletme Müdürlüğü/İlçe İşletme Şefliği Müşteriler veya Abone Servislerine müracaat etmekleri gerekmektedir.
                        </p>

                        <p><strong>2. Meskende Tüketilen Su Tüketiminde İndirim</strong></p>
                        <p>
                            1005 ve 3292 sayılı kanunlara göre aylık bağlananlar ile malûl gazi olarak aylık bağlanmış olanlar ve şehit dul ve yetimi sıfatıyla aylık veya gelir bağlanmış bulunanların; belediyelerce tahakkuk ettirilecek su ücreti %50'den az indirim içermemek üzere belediye meclisleri tarafından belirlenecek tarife üzerinden alınır. Bu uygulamadan yararlanabilmek için ikamet edilen yerin Belediye Başkanlığına istenilen belgelerle birlikte başvurulması gerekmektedir.
                        </p>

                        <p><strong>3. Kamu Kurum ve Kuruluşlarına Ait Sosyal Tesislerden Faydalanma</strong></p>
                        <p>
                            Şehitlerimizin eş, anne, baba ve çocukları Başbakanlığın 2006/16 sayılı genelgesi gereğince, ikametgah mahalleri dışına çıktıklarında kamu kurum ve kuruluşlarına ait misafirhane ve sosyal tesislerden bu kurum ve kuruluşların kendi personeline uyguladığı ücret tarifesine göre yararlandırılmaktadırlar.
                        </p>

                        <p><strong>4. 18 Mart Şehitler Gününde Şehit Yakınlarına İdari İzin Verilmesi</strong></p>
                        <p>
                            2005/27 sayılı Başbakanlık Genelgesi ile; 18 Mart Şehitler Gününde yapılacak etkinliklere rahatlıkla katılabilmelerini sağlamak amacıyla kamu kurum/kuruluşlarında çalışan şehit dul ve yetimleri ile anne, baba ve kardeşlerinin 18 Mart Şehitler Gününde idari izinli sayılmışlardır.
                        </p>

                        <p><strong>5. Devlet Memuru Olarak Görev Yapan Şehit Yakınlarının Atanmasında Kolaylık</strong></p>
                        <p>
                            “Devlet Memurlarının Yer Değiştirme Suretiyle Atanmalarına İlişkin Yönetmeliğe” ilave edilen bir madde ile; terörle mücadelede çalışamayacak derecede malul olan ya da malul olup da çalışabilir olan kamu görevlileri ile erbaş ve erlerin, Devlet memuru olarak görev yapan eş ve çocukları ile anne, baba ve kardeşlerinin atanma taleplerinin yerine getirilmesi kolaylaştırılmıştır.
                        </p>

                        <p><strong>6. Milli Piyango İdaresi Genel Müdürlüğü'nce Verilen Bayilik Ruhsatlarında Kolaylık</strong></p>
                        <p>
                            İdarece, yerleşim yerlerinde bayilik verme ihtiyacının bulunması halinde baş bayiler ile 12/4/1991 tarihli ve 3713 sayılı Terörle Mücadele Kanununun değişik 21. maddesinin birinci fıkrasının (d) bendinde sayılanlara bir yıldır faaliyet gösterme şartı aranmaksızın ve 31/12/1960 tarihli ve 193 sayılı Gelir Vergisi Kanunu hükümlerine göre çalışma gücünün en az yüzdekırk (%40) ını kaybetmiş olanlardan bu maddedeki diğer koşulları taşıyan gerçek kişilere kura çekilişine tabi tutulmaksızın öncelik tanınır.
                        </p>

                        <p><strong>7. Sosyal Hizmetler ve Çocuk Esirgeme Kurumunca Sağlanan Kolaylıklar</strong></p>
                        <p>
                            Yardıma, bakıma ve korunmaya ihtiyaç duyan şehitlerimizin eş, çocuk, anne ve babalarını Sosyal Hizmetler ve Çocuk Esirgeme Kurumu Genel Müdürlüğüne bağlı; çocuk yuvaları, yetiştirme yurtları, kreş ve gündüz bakım evleri, huzur evleri, bakım ve rehabilitasyon merkezleri, kadın misafirhaneleri ve yaşlı danışma merkezlerinde, kurum olanakları ve ilgili yönetmelikler çerçevesinde ihtiyaç sahiplerinin başvuruları öncelikle kabul edilmektedir.
                        </p>
                        <p>
                            Ayrıca, Ülkemizde ağır özürlü olanlara belirli şartları taşımaları halinde bakım hizmeti verilmektedir.
                        </p>

                        <p><strong>8. Bazı Üniversitelerin Sağladığı Kolaylıklar</strong></p>
                        <p>
                            - T.C. Ondokuzmayıs Üniversitesi: Terörle mücadelede şehit olan T.S.K' ne mensup şehitlerden sosyal güvencesi bulunmayan anne, baba, eş ve çocuklarına üniversitenin hastane ve diş hekimliği fakültesinde ücretsiz teşhis ve tedavi hizmeti sunulmaktadır.
                        </p>
                        <p>
                            - T.C. Fırat Üniversitesi: Ülkemizin vatanı ve milleti ile bölünmez bütünlüğünü korumak için şehit düşen ve sosyal güvencesi bulunmayan, T.S.K. mensubu er ve erbaşların anne, baba, eş ve çocuklarının muayene, tetkik ve tedavileri Fırat Tıp Merkezinde ücretsiz olarak yapılmaktadır.
                        </p>

                        <p><strong>9. Hac Kontenjanından Yararlanma Hakkı</strong></p>
                        <p>
                            Diyanet İşleri Başkanlığınca Bakanlıklararası Hac ve Umre Kurulu kararları çerçevesinde 2007 ve 2008 yılı hac organizasyonlarında olduğu gibi 2009 yılı hac organizasyonunda da şehitlerimizin, daha önce hacca gitmeyen ve müracaat süresi içerisinde kayıt yaptırmış olan anne, baba ve eşleri ile kayıt yaptırmış olan gazilerimiz ve eşlerinden sırası gelmeyenlere; şehit ailesi ve gazi olduklarını Genel Kurmay Başkanlığı, Emniyet Genel Müdürlüğü veya Valiliklerden alacakları belge ile ibraz etmeleri şartıyla kayıt hakkı verilmiştir.
                        </p>

                        <p><strong>10. Sağlık Kurulu Raporları İçin Ücret Alınmaması</strong></p>
                        <p>
                            4736 sayılı Kanunun verdiği yetkiyle çıkarılan 3654 sayılı Bakanlar Kurulu Kararına dayanılarak çıkarılan “Sağlık Bakanlığı, Tedavi Hizmetleri Genel Müdürlüğünün 03.03.2004 tarih ve 2004/30 sayılı genelgesi uyarınca; şehit yakını ve gazilerimizin özel kanunlar uyarınca kendilerine tanınan haklardan yararlanabilmeleri maksadıyla almaları gereken özürlü sağlık kurulu raporları için muayene, tetkik, rapor ücreti, bağış ve evrak parası adı altında herhangi bir ücret talep edilmemesi karara bağlanmıştır.
                        </p>

                        <p><strong>11. GSM Operatörlerinin Sağladığı Kolaylıklar</strong></p>
                        <p>
                            Şehit yakını ve gazilerimize Avea, Turkcell, Telsim tarafından indirimli ücret tarifesi uygulanmaktadır.
                        </p>

                        <p><strong>12. Belediyelerin Arsa, Konut ve İşyeri Üretimi, Tahsisi, Kiralaması ve Satışında Şehit Yakını ve Gazilere Sağlanan Kolaylıklar</strong></p>
                        <p>
                            29.09.2005 tarih ve 25951 sayılı Resmi Gazete'de yayımlanarak yürürlüğe giren, Belediyelerin Arsa, Konut ve İşyeri Üretimi, Tahsisi, Kiralaması ve Satışına Dair Genel Yönetmelikte; Belediyeler tarafından üretilen arsa ve sosyal konutların söz konusu yönetmelik kapsamında aşağıda sayılan şartları taşıyanlara Kıymet Takdir Komisyonu tarafından belirlenen bedeli karşılığında ihalesiz olarak tahsis edilmesini karara bağlamaktadır.
                        </p>
                        <p>
                            Yönetmeliğin 7. maddesine göre konut yapılmak amacıyla arsa tahsis edilecekler ile sosyal konut tahsis edileceklerde aşağıdaki şartlar aranmaktadır:
                        </p>
                        <ul>
                            <li>Şehitlerin eş, anne, baba ve çocukları</li>
                            <li>Malul gaziler ile malul sayılacak derecede ağır yaralanmış olanlar</li>
                            <li>Devlet memurlarının, şehitlerin anne ve babalarının sosyal sigorta haklarını kullananlar</li>
                            <li>Şehitlerin dul ve yetimleri</li>
                            <li>Başka bir yerden evi olmayanlar</li>
                            <li>Devlet memurları ve bunların eş ve çocukları</li>
                        </ul>
                    </>
                )

        },
        // Add other rights here

    ]
};

export default Media;
