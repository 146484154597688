import React from 'react';
import { Carousel, Col } from 'react-bootstrap';
import '../css/ImageGallery.css'; // Stil dosyası
import ImageCarouselGallery from './ImageCarouselGallery';

const ImageGallery = ({ galleries, openModal }) => {
    return (
        <>
            {galleries.map((gallery, index) => (
                <Col md={4} key={index}>
                    <Carousel>
                        {gallery.map((image, imgIndex) => (
                            <Carousel.Item key={imgIndex}>
                                <img
                                    className="gallery-image"
                                    src={image}
                                    alt={`Gallery Image ${imgIndex + 1}`}
                                    onClick={() => openModal(<ImageCarouselGallery images={gallery} />)}
                                    style={{ height: '300px', objectFit: 'contain' }} // Added inline style for better control
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>

                </Col>
            ))}
        </>
    );
};

export default ImageGallery;
