import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../css/AnnouncementPage.css'; // Stil dosyanızı buraya ekleyin
import data from '../assets/data/Media.js'; // İç içe veri yapısını import edin

const AnnouncementsPage = () => {
    return (
        <Container className="announcements-page">
            <h1 className="text-center mb-4">Duyurular</h1>
            <Row>
                {data.announcements.map((announcement, index) => (
                    <Col md={4} key={index} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>{announcement.title}</Card.Title>
                                <Card.Text>
                                    {announcement.detail}
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">{announcement.date}</small>
                                    {/*  <a href={announcement.link} className="btn btn-primary float-right">Devamını Oku</a>*/}
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default AnnouncementsPage;
