import React from 'react';
import { Carousel } from 'react-bootstrap';

const NewsCarousel = ({ newsItems }) => {
    return (
        <Carousel>
            {newsItems.map((item, index) => (
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-100"
                        src={item.image}
                        alt={`Slide ${index}`}
                        style={{ height: '400px', objectFit: 'cover' }}
                    />
                    <Carousel.Caption>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default NewsCarousel;

