import React from 'react';
import '../css/VideoEmbed.css'; // VideoEmbed için stil dosyası

const VideoEmbedModal = ({ video }) => {
    return (
        <div className="video-container-modal">
            <iframe
                src={video.url}
                title="Video Modal"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
};

export default VideoEmbedModal;
