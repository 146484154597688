import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import misyon from '../assets/misyon.png';
import vizyon from '../assets/vizyon.png';

const About = () => {
  return (
    <Container className="my-4">
      <Row>
        <Col>
          <h1>Hakkımızda</h1>

          {/* Misyon Bölümü */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img
              src={misyon}
              alt="Misyon"
              style={{
                width: '80%',
                maxWidth: '250px', // Maksimum genişlik
                minWidth: '150px', // Minimum genişlik
                borderRadius: '8px'
              }}
            />
            <p>
              2016 yılında Türkiye Şehit Aileleri ve Gaziler Vakfı olarak kurulan teşkilatımız,
              amacına uygun olarak, şehit yakınları ve gazilerimizin öncelikle eğitim, sağlık ve
              istihdam olmak üzere her türlü gereksinimlerini karşılayarak yaşam kalitelerini
              yükseltmek amaçlı kalıcı çözümler üretmeyi misyon edinen bir kuruluştur.
            </p>
          </div>

          {/* Vizyon Bölümü */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img
              src={vizyon}
              alt="Vizyon"
              style={{
                width: '100%',
                maxWidth: '250px', // Maksimum genişlik
                minWidth: '150px', // Minimum genişlik
                borderRadius: '8px'
              }}
            />
            <p>
              Atatürk ilkeleri çerçevesinde, mesleki uzmanlık, tarafsızlık, şeffaflık, gizlilik,
              güvenirlilik ve kalite standartları esaslarını benimseyerek verimli projeler
              üreterek, bu projeleri vakıf yönetmeliğine ve temel ilkelerimize uygun şekilde
              hayata geçirmek, üyelerine hızlı ve adil şekilde yardımda bulunulmasını sağlamak,
              üyeler ve vakıf arasında en etkin iletişim ve bilgi ağını oluşturarak gerekli
              ihtiyaçlara en kalıcı şekilde cevap vermektir.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
