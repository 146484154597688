import React from 'react';
import { Col } from 'react-bootstrap';
import '../css/VideoEmbed.css'; // VideoEmbed için stil dosyası
import VideoEmbedModal from './VideoEmbedModal';

const VideoEmbed = ({ videos, openModal }) => {
    return (
        <>
            {videos.map((video, index) => (
                <Col md={6} key={index}>
                    <div className="video-container"
                        onClick={() => openModal(<VideoEmbedModal video={video} />)}
                    >
                        <img
                            src={`https://img.youtube.com/vi/${video.url.split('/').pop()}/hqdefault.jpg`} // Video önizleme resmi
                            alt={`Video ${index + 1}`}
                            className="video-thumbnail"
                        />
                        <div className="play-icon">▶</div>
                    </div>
                </Col>
            ))}
        </>
    );
};

export default VideoEmbed;
