import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../css/Branches.css'; // Ensure you create this CSS file for custom styling
import data from '../assets/data/Media'


const Branches = () => {
    return (
        <Container className="branches-container">
        <h2 className="text-center mb-4">Şubelerimiz</h2>
        <Row>
            {data.branches.map((branch, index) => (
                <Col md={4} key={index} className="mb-4">
                    <Card className="branch-card">
                        <Card.Body>
                            <Card.Title>{branch.name}</Card.Title>
                            <Card.Text>
                                <strong>Adres:</strong> {branch.address}
                                <br />
                                <strong>Telefon:</strong> {branch.phone}
                                <br />
                                <strong>Email:</strong> <a href={`mailto:${branch.email}`}>{branch.email}</a>
                                <br />
                                <br />
                                <strong>Yetkili Kişi:</strong> {branch.authorizedPerson}
                                <br />
                                <strong>Yetkili Telefon:</strong> {branch.authorizedPhone}
                                <br />
                                <strong>Yetkili Email:</strong> <a href={`mailto:${branch.authorizedEmail}`}>{branch.authorizedEmail}</a>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </Container>
    );
};

export default Branches;
