import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../css/NewsPage.css'; // Stil dosyanızı buraya ekleyin
import data from '../assets/data/Media'; // İç içe veri yapısını import edin

const NewsPage = () => {
    return (
        <Container className="news-page">
            <h1 className="text-center mb-4">Haberler</h1>
            <Row>
                {data.news.map((item, index) => (
                    <Col md={4} key={index} className="mb-4">
                        <Card>
                            <Card.Img variant="top" src={item.image} />
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <Card.Text>
                                    {item.text}
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">{item.date}</small>
                                    {
                                    //<a href={item.link} className="btn btn-primary float-right">Devamını Oku</a>
                                    }
                                    </Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default NewsPage;
