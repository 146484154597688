import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getProfile, updateProfile } from '../utils/api';
import '../css/ProfilePage.css';
import profilePhoto from '../assets/profile.png';

const ProfilePage = () => {
    const [user, setUser] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        email: '',
        profilePic: profilePhoto,
    });
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData();
    }, [navigate]);

    const handleEditToggle = () => {
        setEditMode(!editMode);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await updateProfile(user);
            setEditMode(false);
        } catch (error) {
            console.error('Kullanıcı bilgilerini güncellerken hata oluştu:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserData = async () => {
        try {
            const userData = await getProfile();
            setUser({
                first_name: userData.first_name,
                last_name: userData.last_name,
                phone: userData.phone,
                address: userData.address,
                email: userData.email,
                profilePic: userData.profile_pic || profilePhoto,
            });
        } catch (error) {
            console.error('Kullanıcı bilgilerini alırken hata oluştu:', error);
            navigate('/');
        }
    };

    return (
        <Container className="mt-5 mb-5">
            <Row className="justify-content-center">
                <Col md={4}>
                    <Card className="text-center shadow-sm">
                        <div className="profile-header">
                            <Card.Img
                                variant="top"
                                src={user.profilePic instanceof File ? URL.createObjectURL(user.profilePic) : user.profilePic}
                                className="profile-img mx-auto mt-4"
                            />
                        </div>
                        <Card.Body>
                            <Card.Title className="mb-2">{user.first_name} {user.last_name}</Card.Title>
                            <Card.Text className="text-muted mb-4">{user.email}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8}>
                    <Card className="shadow-sm">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">{editMode ? 'Profili Düzenle' : 'Profil Bilgileri'}</h5>
                            <Button variant="outline-primary" onClick={handleEditToggle}>
                                {editMode ? 'İptal' : 'Düzenle'}
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSave}>
                                <Form.Group controlId="formProfilePic" className="mb-3">
                                    <Form.Label>Profil Resmi</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                setUser({ ...user, profilePic: file });
                                            }
                                        }}
                                        disabled={!editMode}
                                        className="shadow-sm"
                                    />
                                </Form.Group>

                                <Form.Group controlId="formFirstName" className="mb-3">
                                    <Form.Label>Ad</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={user.first_name}
                                        onChange={(e) => setUser({ ...user, first_name: e.target.value })}
                                        disabled={!editMode}
                                        className={`shadow-sm ${editMode ? 'border-primary' : ''}`}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formLastName" className="mb-3">
                                    <Form.Label>Soyad</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={user.last_name}
                                        onChange={(e) => setUser({ ...user, last_name: e.target.value })}
                                        disabled={!editMode}
                                        className={`shadow-sm ${editMode ? 'border-primary' : ''}`}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={user.email}
                                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                                        disabled={!editMode}
                                        className={`shadow-sm ${editMode ? 'border-primary' : ''}`}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPhone" className="mb-3">
                                    <Form.Label>Telefon</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={user.phone}
                                        onChange={(e) => setUser({ ...user, phone: e.target.value })}
                                        disabled={!editMode}
                                        className={`shadow-sm ${editMode ? 'border-primary' : ''}`}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formAddress" className="mb-3">
                                    <Form.Label>Adres</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={user.address}
                                        onChange={(e) => setUser({ ...user, address: e.target.value })}
                                        disabled={!editMode}
                                        className={`shadow-sm ${editMode ? 'border-primary' : ''}`}
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit" disabled={!editMode || loading} className="me-2">
                                    {loading ? <Spinner animation="border" size="sm" /> : 'Kaydet'}
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ProfilePage;
