import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../css/MessageOfPresident.css'; // Ensure you create this CSS file for custom styling
import baskanImg from '../assets/galery/resim7.jpg'
import baskanMessage from '../assets/message.svg'


const MessageOfPresident = () => {
    return (
        <Container className="president-message-container">
            {/* Resmi ekleyin */}
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={baskanMessage} alt="Türkiye Şehit Aileleri ve Gaziler Vakfı" style={{
                    width: '80%',
                    maxWidth: '250px', // Maksimum genişlik
                    minWidth: '150px', // Minimum genişlik
                    borderRadius: '8px'
                }} />
            </div>

            <Row>
                <Col md={4} className="mb-4">
                    <Image
                        src={baskanImg} // Replace with the actual image URL
                        alt="Başkan"
                        fluid
                        className="president-image"
                    />
                </Col>
                <Col md={8}>
                    <div class="content">

                        <p class="title">Türkiye Şehit Aileleri ve Gaziler Vakfı olarak amacımız;</p>
                        <p>
                            Atatürk ilkeleri ışığında nitelikli bireylerin yetiştirilmesi, şehit ve gazi ailelerinin
                            eğitim, sağlık ve istihdam gibi temel ihtiyaçlarını göz önünde bulundurarak,
                            mesleki uzmanlık, tarafsızlık, şeffaflık, gizlilik, güvenirlilik esaslarını
                            benimseyerek, tüm bunlar için en yüksek çabayı göstermektir.
                        </p>
                        <p>
                            Ülkemiz uğruna canını feda eden şehitlerimizin geride bıraktıkları eşleri,
                            çocukları, anaları, babaları için ve yine ülkemiz uğruna bedenini siper etmiş
                            gazilerimiz için yurdumuzun tüm insanı ile birlikte elimizden gelen her şeyi
                            yapma zamanıdır...
                        </p>
                    </div>
                    <p>
                        Saygılarımla,<br />
                        Gazi Üstüner MİDİLİÇ<br />
                        TUSAG Genel Başkanı
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default MessageOfPresident;
