import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import '../css/Footer.css'; // Stiller için dosya
import logo from '../logo.svg';

const Footer = () => {
  return (
    <footer className="footer bg-light py-4">
      <Container>
        <Row>
          {/* TUSAG Amblemi, Misyon - Vizyon */}
          <Col md={4}>
            <img
              src={logo} // TUSAG logosunun URL'si
              alt="TUSAG Logo"
              style={{ width: '300px' }}
            />
            {/*<h5>Misyon & Vizyon</h5>*/}
            <p>
              Vakfımız, Şehit Aileleri ve Gazilerimize Eğitim, Sağlık ve İstihdam Olanakları Sağlamak İçin kurulmuştur.
            </p>
          </Col>

          {/* TUSAG Bölümü ve Alt Menüler */}
          <Col md={2}>
            <h5>TUSAG</h5>
            <ul className="list-unstyled">
              <li><Link to="/about">Hakkımızda</Link></li>
              <li><Link to="/message">Başkanın Mesajı</Link></li>
              <li><Link to="/branches">Şubelerimiz</Link></li>
              <li><Link to="/news">Haberler</Link></li>
              <li><Link to="/announcement">Duyurular</Link></li>

            </ul>
          </Col>

          {/* Bağışlar Bölümü ve Bağış Türleri */}
          <Col md={2}>
            <h5>Bağışlar</h5>
            <ul className="list-unstyled">
              {/*<li><Link to="/burslarimiz">Burslar</Link></li>*/}
              <li><Link to="/donate">Bağışlar</Link></li>
              <li><Link to="/bankAccount">Banka Hesaplarımız</Link></li>
            </ul>
          </Col>

          {/* İletişim ve Sosyal Medya */}
          <Col md={3}>
            <h5>İletişim</h5>
            <p> <a href="https://www.google.com/maps/search/K%C4%B1z%C4%B1l%C4%B1rmak+Mah.+1443.+cad.+No:33%2F16+%C3%87ankaya%2FAnkara+,+Ankara,+Turkey/@39.9047401,32.809234,17z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MDkxNy4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer"><FaMapMarkerAlt />
            </a> Kızılırmak Mah. 1443. cad. No:33/16 Çankaya/Ankara , Ankara, Turkey</p>
            <p><a href="tel:+02121234567"><FaPhone /> </a> (0312) 433 83 80</p>
            <p> <a href="mailto:info@tugas.org"><FaEnvelope /></a> info@tusag.com.tr</p>

          </Col>
          {/* Sosyal Medya */}
          <Col md={1}>
            <div className="social-icons">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} className="d-block mb-2" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={30} className="d-block mb-2" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} className="d-block mb-2" />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                <FaYoutube size={30} className="d-block mb-2" />
              </a>
            </div>
          </Col>
        </Row>
        {/* Copyright */}
        <Row className="mt-4">
          <Col className="text-center">
            <p className="mb-0">© 2024 MDSiber. Tüm Hakları Saklıdır.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
