import React from 'react';
import { Link } from 'react-router-dom';
import '../css/AnnouncementsList.css';

const AnnouncementsList = ({ announcements }) => {
    return (
        <div className="announcements-list">
            {announcements.map((announcement, index) => (
                <Link to={announcement.link} key={index} className="announcement-link">
                    <div className="announcement-item">
                        <div className="announcement-content">
                            <h4 className="announcement-title">{announcement.title}</h4>
                            <p className="announcement-detail">{announcement.detail}</p>
                        </div>
                        <div className="arrow-icon">{'>>'}</div>
                        <p className="announcement-date">{announcement.date}</p> {/* Tarihi sağ alta ekledik */}
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default AnnouncementsList;
