import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../css/Contact.css'; // Create this CSS file for custom styling

const Contact = () => {
    return (
        <Container className="contact-container my-4">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h1 className="text-center mb-4">Bize Ulaşın</h1>
                    <p className="text-center mb-4">
                        Sorularınızı veya yorumlarınızı bize iletmekten çekinmeyin.
                        Aşağıdaki formu doldurarak bizimle iletişime geçebilirsiniz.
                    </p>
                    <Form>
                        <Form.Group controlId="formName">
                            <Form.Label>İsim</Form.Label>
                            <Form.Control type="text" placeholder="İsminizi girin" required />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email adresinizi girin" required />
                        </Form.Group>

                        <Form.Group controlId="formMessage">
                            <Form.Label>Mesaj</Form.Label>
                            <Form.Control as="textarea" rows={4} placeholder="Mesajınızı yazın" required />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-3 w-100">
                            Mesaj Gönder
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;
