// src/components/GalleryList.js

import React, { useEffect, useState } from 'react';
import { getAllGalleries, createGallery, updateGallery, deleteGallery } from '../utils/api'; // Update these as necessary
import { toast, ToastContainer } from 'react-toastify';
import '../css/GalleryList.css'; // You can use the same CSS file or create a new one

const GalleryList = () => {
    const [galleries, setGalleries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newGallery, setNewGallery] = useState({
        title: '', detail: '', date: new Date().toISOString().split('T')[0], gallery_pic: ''
    });
    const [editingGallery, setEditingGallery] = useState(null);
    const [isEditingEnabled, setIsEditingEnabled] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);

    const fetchGalleries = async () => {
        setLoading(true);
        try {
            const data = await getAllGalleries();
            setGalleries(data);
        } catch (error) {
            toast.error(`Error loading galleries: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGalleries();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewGallery({ ...newGallery, [name]: value });
    };
    const handleImageChange = (e) => {
        setNewGallery({ ...newGallery, image: e.target.files[0] });
    };
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditingGallery({ ...editingGallery, [name]: value });
    };
    const handleEditImageChange = (e) => {
        setEditingGallery({ ...editingGallery, image: e.target.files[0] });
    };
    const handleAddGallery = async (e) => {
        e.preventDefault();
        if (!isFormValid(newGallery)) {
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            const addedGallery = await createGallery(newGallery);
            setGalleries([addedGallery, ...galleries]);
            setNewGallery({ title: '', detail: '', date: new Date().toISOString().split('T')[0], gallery_pic: '' });
            toast.success('Gallery added successfully!');
        } catch (error) {
            toast.error(`Error adding gallery: ${error.message}`);
        }
    };

    const handleEditGallery = (gallery) => {
        setEditingGallery(gallery);
        setIsEditingEnabled(true);
    };

    const handleUpdateGallery = async (e) => {
        e.preventDefault();
        if (!isFormValid(editingGallery)) { // Eğer form geçersizse API çağrısı yapılmaz
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            await updateGallery(editingGallery.id, editingGallery);
            setGalleries(galleries.map((gallery) => (gallery.id === editingGallery.id ? editingGallery : gallery)));
            setEditingGallery(null);
            setIsEditingEnabled(false);
            toast.success('Gallery updated successfully!');
        } catch (error) {
            toast.error(`Error updating gallery: ${error.message}`);
        }
    };

    const handleDelete = async (id) => {
        toast(
            ({ closeToast }) => (
                <div>
                    <p>Gerçekten silmek istiyor musunuz?</p>
                    <button onClick={() => confirmDelete(id, closeToast)} className="btn btn-danger">
                        Evet
                    </button>
                    <button onClick={closeToast} className="btn btn-secondary">
                        Hayır
                    </button>
                </div>
            ),
            {
                position: "bottom-right",
                autoClose: false, // Otomatik kapanmayı devre dışı bırakıyoruz, kullanıcıya karar vermesi için zaman tanıyacak.
                closeOnClick: false, // Butonlarla kontrol edeceğimiz için otomatik kapanma olmasın.
                draggable: false // Toast kutusunu taşımaya çalışmaması için devre dışı.
            }
        );
    };
    const confirmDelete = (id, closeToast) => {
        // Silme işlemi burada gerçekleştirilecek
        try {
            deleteGallery(id);
            setGalleries(galleries.filter(gallery => gallery.id !== id));
            toast.success('Fotograf başarıyla silindi!');
        } catch (error) {
            toast.error(`Fotograf silinirken hata: ${error.message}`);
        }
        closeToast(); // Toast'u manuel olarak kapatıyoruz.
    };
    const handleCancelEdit = () => {
        setEditingGallery(null); // Düzenleme modunu kapatır
        setIsEditingEnabled(false); // Düzenlemeyi devre dışı bırakır
    };
    const isFormValid = (branch) => {
        const requiredFields = ['title', 'detail', 'date', 'gallery_pic'];
        for (let field of requiredFields) {
            if (!branch[field]) {
                toast.error('Tüm alanların doldurulması gerekmektedir!');
                return false;
            }
        }
        return true;
    };
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="gallery-list-container mb-5">
            <table className="gallery-list-table">
                <thead>
                    <tr>
                        <th>Başlık</th>
                        <th>İçerik</th>
                        <th>Fotograf</th>
                        <th>Tarih</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="title"
                                placeholder="Title"
                                value={newGallery.title}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}

                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="detail"
                                placeholder="İçerik"
                                value={newGallery.detail}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}

                            />
                        </td>
                        <td>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="date"
                                name="date"
                                value={newGallery.date}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <button type="button" onClick={handleAddGallery} className="btn-gallery" title="Fotograf Ekle" disabled={!isEnabled}>
                                <i className="fas fa-plus"></i>
                            </button>
                            <button type="button" onClick={() => setIsEnabled(!isEnabled)} className="btn-gallery" title="Ekleme Modunu Aç/Kapa">
                                <i className="fas fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                    {galleries.map((gallery) => (
                        <tr key={gallery.id}>
                            {editingGallery?.id === gallery.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            name="title"
                                            value={editingGallery.title}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="detail"
                                            value={editingGallery.detail}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}

                                        />
                                    </td>

                                    <td>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            disabled={!isEditingEnabled}
                                            onChange={(e) => setEditingGallery({ ...editingGallery, gallery_pic: e.target.files[0] })}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="date"
                                            name="date"
                                            value={editingGallery.date}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={handleUpdateGallery} className="btn-gallery" title="Güncelle">
                                            <i className="fas fa-check"></i>
                                        </button>
                                        <button onClick={handleCancelEdit} className="btn-gallery" title="İptal Et">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{gallery.title}</td>
                                    <td>{gallery.detail}</td>
                                    <td>{gallery.image ? <img src={gallery.image} alt={gallery.title} /> : 'Yok'}</td>
                                    <td>{new Date(gallery.date).toLocaleDateString()}</td>
                                    <td>
                                        <button onClick={() => handleEditGallery(gallery)} className="btn-gallery" title="Düzenle">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button onClick={() => handleDelete(gallery.id)} className="btn-gallery" title="Sil">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer position='bottom-right' />
        </div>
    );
};

export default GalleryList;
