import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const UserDetail = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch(`/api/users/${id}`); // Kullanıcı detayını çeken API
      const data = await response.json();
      setUser(data);
    };

    fetchUser();
  }, [id]);

  if (!user) return <div>Yükleniyor...</div>;

  return (
    <div>
      <h1>Kullanıcı Detayları</h1>
      <p><strong>Kullanıcı Adı:</strong> {user.username}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Durum:</strong> {user.isActive ? 'Aktif' : 'Pasif'}</p>
      <p><strong>Kayıt Tarihi:</strong> {new Date(user.registrationDate).toLocaleDateString()}</p>
      <div>
        <h2>Profil Bilgileri</h2>
        {user.profilePicture && <img src={user.profilePicture} alt="Profil" />}
        {/* Diğer profil bilgilerini burada gösterin */}
      </div>
    </div>
  );
};

export default UserDetail;
