import React, { useEffect, useState } from 'react';
import { getBranches, createBranch, updateBranch, deleteBranch } from '../utils/api';
import { toast, ToastContainer } from 'react-toastify';
import '../css/BranchesList.css';

const BranchesList = () => {
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newBranch, setNewBranch] = useState({
        name: '',
        address: '',
        phone: '',
        email: '',
        authorized_person: '',
        authorized_phone: '',
        authorized_email: ''
    });
    const [editingBranch, setEditingBranch] = useState(null);
    const [isEditingEnabled, setIsEditingEnabled] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);

    const fetchBranches = async () => {
        try {
            const data = await getBranches();
            setBranches(data);
        } catch (error) {
            toast.error(`Şubeleri yüklerken hata: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBranches();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBranch({ ...newBranch, [name]: value });
    };

    const isFormValid = (branch) => {
        const requiredFields = ['name', 'address', 'phone', 'email', 'authorized_person', 'authorized_phone', 'authorized_email'];
        for (let field of requiredFields) {
            if (!branch[field]) {
                toast.error('Tüm alanların doldurulması gerekmektedir!');
                return false;
            }
        }
        return true;
    };

    const handleAddBranch = async (e) => {
        e.preventDefault();
        if (!isFormValid(newBranch)) return; // Eğer form geçersizse API çağrısı yapılmaz

        try {
            const addedBranch = await createBranch(newBranch);
            setBranches([addedBranch, ...branches]);
            setNewBranch({ name: '', address: '', phone: '', email: '', authorized_person: '', authorized_phone: '', authorized_email: '' });
            toast.success('Şube başarıyla eklendi!');
        } catch (error) {
            toast.error(`Şube eklenirken hata: ${error.message}`);
        }
    };

    const handleEditBranch = (branch) => {
        setEditingBranch(branch);
        setIsEditingEnabled(true);
    };

    const handleUpdateBranch = async (e) => {
        e.preventDefault();
        if (!isFormValid(editingBranch)) return; // Eğer form geçersizse API çağrısı yapılmaz

        try {
            await updateBranch(editingBranch.id, editingBranch);
            setBranches(branches.map((branch) => (branch.id === editingBranch.id ? editingBranch : branch)));
            setEditingBranch(null);
            setIsEditingEnabled(false);
            toast.success('Şube başarıyla güncellendi!');
        } catch (error) {
            toast.error(`Şube güncellenirken hata: ${error.message}`);
        }
    };
    const handleDelete = async (id) => {
        toast(
            ({ closeToast }) => (
                <div>
                    <p>Gerçekten silmek istiyor musunuz?</p>
                    <button onClick={() => confirmDelete(id, closeToast)} className="btn btn-danger">
                        Evet
                    </button>
                    <button onClick={closeToast} className="btn btn-secondary">
                        Hayır
                    </button>
                </div>
            ),
            {
                position: "bottom-right",
                autoClose: false, // Otomatik kapanmayı devre dışı bırakıyoruz, kullanıcıya karar vermesi için zaman tanıyacak.
                closeOnClick: false, // Butonlarla kontrol edeceğimiz için otomatik kapanma olmasın.
                draggable: false // Toast kutusunu taşımaya çalışmaması için devre dışı.
            }
        );
    };
    const confirmDelete = (id, closeToast) => {
        // Silme işlemi burada gerçekleştirilecek
        try {
            deleteBranch(id);
            setBranches(branches.filter(branch => branch.id !== id));
            toast.success('Şube başarıyla silindi!');
        } catch (error) {
            toast.error(`Şube silinirken hata: ${error.message}`);
        }
        closeToast(); // Toast'u manuel olarak kapatıyoruz.
    };
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditingBranch({ ...editingBranch, [name]: value });
    };
    const handleCancelEdit = () => {
        setEditingBranch(null); // Düzenleme modunu kapatır
        setIsEditingEnabled(false); // Düzenlemeyi devre dışı bırakır
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="branch-list-container mb-5">
            <table className="branch-list-table">
                <thead>
                    <tr>
                        <th>Ad</th>
                        <th>Adres</th>
                        <th>Telefon</th>
                        <th>Email</th>
                        <th>Yetkili Kişi</th>
                        <th>Yetkili Telefon</th>
                        <th>Yetkili Email</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="name"
                                placeholder="Şube Adı"
                                value={newBranch.name}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="address"
                                placeholder="Adres"
                                value={newBranch.address}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="phone"
                                placeholder="Telefon"
                                value={newBranch.phone}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={newBranch.email}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="authorized_person"
                                placeholder="Yetkili Kişi"
                                value={newBranch.authorized_person}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="authorized_phone"
                                placeholder="Yetkili Telefon"
                                value={newBranch.authorized_phone}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="email"
                                name="authorized_email"
                                placeholder="Yetkili Email"
                                value={newBranch.authorized_email}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <button type="button" onClick={handleAddBranch} className="btn-branch" title="Şube Ekle" disabled={!isEnabled}>
                                <i className="fas fa-plus"></i>
                            </button>
                            <button type="button" onClick={() => setIsEnabled(!isEnabled)} className="btn-branch" title="Düzenlemeye Açık">
                                <i className="fas fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                    {branches.map((branch) => (
                        <tr key={branch.id}>
                            {editingBranch?.id === branch.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            name="name"
                                            value={editingBranch?.name}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="address"
                                            value={editingBranch?.address}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={editingBranch?.phone}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="email"
                                            name="email"
                                            value={editingBranch?.email}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="authorized_person"
                                            value={editingBranch?.authorized_person}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="authorized_phone"
                                            value={editingBranch?.authorized_phone}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="email"
                                            name="authorized_email"
                                            value={editingBranch?.authorized_email}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={handleUpdateBranch} className="btn-branch" title="Güncelle">
                                            <i className="fas fa-check"></i>
                                        </button>
                                        <button onClick={handleCancelEdit} className="btn-branch" title="İptal Et">
                                            <i className="fas fa-times"></i>
                                        </button>

                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{branch.name}</td>
                                    <td>{branch.address}</td>
                                    <td>{branch.phone}</td>
                                    <td>{branch.email}</td>
                                    <td>{branch.authorized_person}</td>
                                    <td>{branch.authorized_phone}</td>
                                    <td>{branch.authorized_email}</td>
                                    <td>
                                        <button onClick={() => handleEditBranch(branch)} className="btn-branch" title="Düzenle">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button onClick={() => handleDelete(branch.id)} className="btn-branch" title="Sil">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer position='bottom-right' />
        </div>
    );
};

export default BranchesList;
