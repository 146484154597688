import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Form, Button, Spinner } from 'react-bootstrap';
import '../css/MessageOfPresidentEdit.css'; // CSS dosyası
import baskanImg from '../assets/galery/resim7.jpg';
import baskanMessage from '../assets/message.svg';
import { getStaticTextById, updateStaticText } from '../utils/api'; // API fonksiyonları

const MessageOfPresidentEdit = () => {
    const [messageContent, setMessageContent] = useState(''); // İçerik state'i
    const [loading, setLoading] = useState(true); // Yükleme durumu
    const [isSubmitting, setIsSubmitting] = useState(false); // Form gönderimi durumu

    useEffect(() => {
        const fetchMessageContent = async () => {
            try {
                const response = await getStaticTextById(4); // ID ile içeriği getir
                setMessageContent(response.content); // Gelen içeriği state'e koy
            } catch (error) {
                console.error('Başkan mesajı yüklenirken hata oluştu', error);
            } finally {
                setLoading(false); // Yükleme durumu bitti
            }
        };

        fetchMessageContent(); // Component mount edildiğinde çağır
    }, []);

    // Girdi değişikliği
    const handleInputChange = (e) => {
        setMessageContent(e.target.value); // Direkt string değeri güncelle
    };

    // Form gönderimi
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Form gönderiliyor

        try {
            await updateStaticText(4, { content: messageContent }); // İçeriği güncelle

            alert('Mesaj başarıyla güncellendi!');
        } catch (error) {
            console.error('Mesaj güncellenirken hata oluştu', error);
            alert('Mesajı güncellerken bir hata oluştu.');
        } finally {
            setIsSubmitting(false); // Form gönderimi tamamlandı
        }
    };

    // Yükleniyor durumunda spinner göster
    if (loading) {
        return (
            <div className="text-center mt-5">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Yükleniyor...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Container className="president-message-container mt-4">
            {/* Başlık Resmi */}
            <div className="text-center mb-4">
                <img
                    src={baskanMessage}
                    alt="Türkiye Şehit Aileleri ve Gaziler Vakfı"
                    style={{
                        width: '80%',
                        maxWidth: '250px',
                        minWidth: '150px',
                        borderRadius: '8px'
                    }}
                />
            </div>

            <Row>
                {/* Başkanın Resmi */}
                <Col md={4} className="mb-4 ">
                    <Image
                        src={baskanImg}
                        alt="Başkan"
                        fluid
                        className="president-image"
                    />
                </Col>

                {/* Mesaj Formu */}
                <Col md={8}>
                    <Form onSubmit={handleSubmit} className="president-message-form">
                        <Form.Group controlId="formParagraph">
                            <Form.Label>Mesaj İçeriği</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={6}
                                name="content"
                                value={messageContent}
                                onChange={handleInputChange}
                                required
                                placeholder="Başkanın mesajını buraya yazın..."
                                style={{ resize: 'vertical', height: '300px', width: '100%' }}
                            />
                        </Form.Group>

                        <Button 
                            variant="primary" 
                            type="submit" 
                            className="small-button"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Güncelleniyor...' : 'Mesajı Güncelle'}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default MessageOfPresidentEdit;
