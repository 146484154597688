import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../css/SignIn.css'; // Custom CSS for SignIn component
import { login } from '../utils/api'; // API'den login fonksiyonunu içe aktar


const SignIn = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State to handle errors

    const handleSignIn = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        try {
            await login(email, password); // API'den giriş işlemini gerçekleştir
            window.location.href = '/'; // Başarılı girişte yönlendirme
        } catch (err) {
            setErrorMessage('Giriş hatası: ' + err.message);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title text-center">Üye Girişi</h2>
                            <form onSubmit={handleSignIn}>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">E-mail</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="E-mail girin"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Şifre</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Şifre girin"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="creative-signin-btn w-100">
                                    Giriş Yap
                                </button>
                            </form>
                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <small>
                            Üye değil misin? <a href="/signup">Buradan Üyelik oluştur</a>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;