import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageCarousel from '../utils/ImageCarousel';
import NewsCarousel from '../utils/NewsCarousel';
import AnnouncementsList from '../utils/AnnouncementsList';
import ImageGallery from '../utils/ImageGallery';
import VideoEmbed from '../utils/VideoEmbed';
import Modal from '../utils/Modal'; // Modal bileşenini import edin
import '../css/Home.css'; // Stil dosyasını ayrı bir dosyaya ekleyebiliriz
import data from '../assets/data/Media'; // İç içe veri yapısını import edin

const Home = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    return (
        <Container fluid className="home-page">
            {/* Güncel Durum Carousel */}
            <Row className="mb-5">
                <Col>
                    <ImageCarousel images={data.carousel.images} captions={data.carousel.captions} />
                </Col>
            </Row>

            {/* Haberler (Kaydırmalı) */}
            <Row className="mb-4 section-content">
                <Col>
                    <h2>Haberler</h2>
                    <NewsCarousel className="haber" newsItems={data.news} />
                </Col>
            </Row>

            {/* Duyurular (Başlık ve Detay) */}
            <Row className="mb-4 section-content">
                <Col>
                    <h2>Duyurular</h2>
                    <AnnouncementsList announcements={data.announcements} />
                </Col>
            </Row>

            {/* Banner */}
            <Row className="mb-4 ">
                <Col>
                    <div className="banner" />
                </Col>
            </Row>

            {/* Galeri */}
            <Row className="mb-4 section-content">
                <h4>Fotograflar</h4>
                <ImageGallery galleries={data.galleries} openModal={openModal} />
            </Row>

            {/* Videolar */}
            <Row className="mb-4 section-content">
                <h4>Videolar</h4>
                <VideoEmbed videos={data.videos} openModal={openModal} />
            </Row>

            {/* Modal */}
            <Modal isOpen={modalOpen} onClose={closeModal} content={modalContent} />
        </Container>
    );
};

export default Home;
