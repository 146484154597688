import React, { useEffect, useState } from 'react';
import { getAllUsers, createUser, updateUser, deleteUser, resetPassword } from '../utils/api';
import { toast, ToastContainer } from 'react-toastify';  // Import Toastify
import '../css/UserList.css'; // Import the CSS file

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newUser, setNewUser] = useState({
        username: '',
        email: '',
        password: '',
        role: '',
        date: new Date().toISOString().split('T')[0],
        isActive: true,
        isDel: false // Kullanıcı silinmiş mi (varsayılan olarak false)
    });
    const [editingUser, setEditingUser] = useState(null);
    const [isEditingEnabled, setIsEditingEnabled] = useState(false); // Track if editing is enabled
    const [isEnabled, setIsEnabled] = useState(false); // Track if editing is enabled

    const roleMap = {
        1: 'Admin',
        2: 'User',
        3: 'Moderator'
    };

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const data = await getAllUsers();
            setUsers(data);
        } catch (error) {
            toast.error(`Kullanıcıları yüklerken hata: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };
    const isFormValid = (user) => {
        const requiredFields = ['username', 'email', 'password', 'role', 'date', 'isActive', 'isDel'];
        for (let field of requiredFields) {
            if (!user[field]) {
                toast.error('Tüm alanların doldurulması gerekmektedir!');
                return false;
            }
        }
        return true;
    };
    const handleAddUser = async (e) => {
        e.preventDefault();
        if (!isFormValid(newUser)) {
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            const addedUser = await createUser(newUser);
            setUsers([addedUser, ...users]);
            setNewUser({ username: '', email: '', password: '', role: '', date: new Date().toISOString().split('T')[0], isActive: true, isDel: false });
            toast.success('Kullanıcı başarıyla eklendi!');
        } catch (error) {
            toast.error(`Kullanıcı eklenirken hata: ${error.message}`);
        }
    };
    const handleEditUser = (user) => {
        setEditingUser(user);
        setIsEditingEnabled(true);
    };
    const handleUpdateUser = async (e) => {
        e.preventDefault();
        if (!isFormValid(editingUser)) { // Eğer form geçersizse API çağrısı yapılmaz
            toast.error('Lütfen tüm alanları doldurun.');
            return;
        }
        try {
            await updateUser(editingUser.id, editingUser);
            setUsers(users.map((user) => (user.id === editingUser.id ? editingUser : user)));
            setEditingUser(null);
            setIsEditingEnabled(false);
            toast.success('Kullanıcı başarıyla güncellendi!');
        } catch (error) {
            toast.error(`Kullanıcı güncellenirken hata: ${error.message}`);
        }
    };
    const handleDelete = async (id) => {
        toast(
            ({ closeToast }) => (
                <div>
                    <p>Gerçekten silmek istiyor musunuz?</p>
                    <button onClick={() => confirmDelete(id, closeToast)} className="btn btn-danger">
                        Evet
                    </button>
                    <button onClick={closeToast} className="btn btn-secondary">
                        Hayır
                    </button>
                </div>
            ),
            {
                position: "bottom-right",
                autoClose: false, // Otomatik kapanmayı devre dışı bırakıyoruz, kullanıcıya karar vermesi için zaman tanıyacak.
                closeOnClick: false, // Butonlarla kontrol edeceğimiz için otomatik kapanma olmasın.
                draggable: false // Toast kutusunu taşımaya çalışmaması için devre dışı.
            }
        );
    };
    const confirmDelete = (id, closeToast) => {
        // Silme işlemi burada gerçekleştirilecek
        try {
            deleteUser(id);
            setUsers(users.filter(user => user.id !== id));
            toast.success('Kullanıcı başarıyla silindi!');
        } catch (error) {
            toast.error(`Kullanıcı silinirken hata: ${error.message}`);
        }
        closeToast(); // Toast'u manuel olarak kapatıyoruz.
    };
    const handleCancelEdit = () => {
        setEditingUser(null); // Düzenleme modunu kapatır
        setIsEditingEnabled(false); // Düzenlemeyi devre dışı bırakır
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditingUser({ ...editingUser, [name]: value });
    };
    const handleResetPassword = async (id) => {
        if (window.confirm('Şifreyi sıfırlamak istediğinizden emin misiniz?')) {
            try {
                await resetPassword(id);
                toast.success('Şifre başarıyla sıfırlandı!');
            } catch (error) {
                toast.error(`Şifre sıfırlanırken hata: ${error.message}`);
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="user-list-container mb-5">
            <table className="user-list-table">
                <thead>
                    <tr>
                        <th>Kullanıcı Adı</th>
                        <th>Email</th>
                        <th>Şifre</th>
                        <th>Rol</th>
                        <th>Durum</th>
                        <th>Silme</th>
                        <th>Kayıt Tarihi</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="username"
                                placeholder="Kullanıcı Adı"
                                value={newUser.username}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={newUser.email}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <input
                                type="password"
                                name="password"
                                placeholder="Şifre"
                                value={newUser.password}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <select
                                name="role"
                                value={newUser.role_id}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            >
                                <option value={1}>Admin</option>
                                <option value={2}>User</option>
                                <option value={3}>Moderator</option>
                            </select>
                        </td>
                        <td>
                            <select
                                name="isActive"
                                value={newUser.isActive}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            >
                                <option value={true}>Aktif</option>
                                <option value={false}>Pasif</option>
                            </select>
                        </td>                        <td>
                            <select
                                name="isDel"
                                value={newUser.isDel}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            >
                                <option value={true}>Silinmiş</option>
                                <option value={false}>Aktif</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="date"
                                name="date"
                                value={newUser.date}
                                onChange={handleInputChange}
                                required
                                disabled={!isEnabled}
                            />
                        </td>
                        <td>
                            <button type="button" onClick={handleAddUser} className="btn-user" title="Kullanıcı Ekle" disabled={!isEnabled}>
                                <i className="fas fa-plus"></i>
                            </button>
                            <button type="button" onClick={() => setIsEnabled(!isEnabled)} className="btn-user" title="Ekleme Modunu Aç/Kapa">
                                <i className="fas fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                    {users.map((user) => (
                        <tr key={user.id}>
                            {editingUser?.id === user.id ? (
                                <>
                                    <td>
                                        <input
                                            type="text"
                                            name="username"
                                            value={editingUser.username}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="email"
                                            name="email"
                                            value={editingUser.email}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => handleResetPassword(user.id)} className="btn-user" title="Şifreyi Sıfırla">
                                            <i className="fas fa-key"></i> Sıfırla
                                        </button>
                                    </td>
                                    <td>
                                        <select
                                            name="role_id"
                                            value={editingUser.role_id}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        >
                                            <option value={1}>Admin</option>
                                            <option value={2}>User</option>
                                            <option value={3}>Moderator</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            name="is_active"
                                            value={editingUser.is_active}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        >
                                            <option value={1}>Aktif</option>
                                            <option value={0}>Pasif</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            name="is_del"
                                            value={editingUser.is_del}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        >
                                            <option value={1}>Silinmiş</option>
                                            <option value={0}>Aktif</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="date"
                                            name="date"
                                            value={editingUser.created_at}
                                            onChange={handleEditInputChange}
                                            required
                                            disabled={!isEditingEnabled}
                                        />
                                    </td> <td>
                                        <button onClick={handleUpdateUser} className="btn-user" title="Güncelle">
                                            <i className="fas fa-check"></i>
                                        </button>
                                        <button onClick={handleCancelEdit} className="btn-user" title="İptal Et">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <button onClick={() => handleResetPassword(user.id)} className="btn-user" title="Şifreyi Sıfırla">
                                            <i className="fas fa-key"></i> Sıfırla
                                        </button>
                                    </td>
                                    <td>{roleMap[user.role_id]}</td>
                                    <td>{user.is_active ? 'Aktif' : 'Pasif'}</td>
                                    <td>{user.is_del ? 'Silinmiş' : 'Aktif'}</td>
                                    <td>{new Date(user.created_at).toLocaleDateString()}</td>
                                    <td>
                                        <button onClick={() => handleEditUser(user)} className="btn-user" title="Düzenle">
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <button onClick={() => handleDelete(user.id)} className="btn-user" title="Sil">
                                            <i className="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer position='bottom-right' />
        </div>
    );
};

export default UserList;
