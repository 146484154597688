import axios from 'axios';
// API base URL
const API_URL = 'https://api.tusag.com.tr/api';

// Kullanıcı kayıt (signup) fonksiyonu-------------------------------------//
export const signup = async (username, email, password) => {
  try {
    const response = await axios.post(`${API_URL}/signup`, {
      username,
      email,
      password,
    });

    return response.data; // Kayıt başarılı mesajı
  } catch (error) {
    throw new Error(error.response?.data || 'Kayıt hatası');
  }
};
// Kullanıcı giriş (login) fonksiyonu--------------------------------------//
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/signin`, {
      email,
      password,
    });
    const token = response.data.token;
    localStorage.setItem('token', token); // Token'ı localStorage'a kaydet

    return response.data; // Giriş başarılı, token döner
  } catch (error) {
    throw new Error(error.response?.data || 'Giriş hatası');
  }
};
// Şifre sıfırlama (reset password) fonksiyonu-----------------------------//
export const resetPassword = async (userId) => { // Change 'email' to 'userId'
  try {
    const response = await axios.post(`${API_URL}/reset-password`, {
      userId, // Send userId in the request body
    });

    return response.data; // Şifre sıfırlama başarılı mesajı
  } catch (error) {
    throw new Error(error.response?.data || 'Şifre sıfırlama hatası'); // Handle errors
  }
};
//-------------------------------------------------------------------------XX




//----------------------------------------------------------------------//
export const getProfile = async () => {
  try {
    const token = localStorage.getItem('token'); // Token'ı localStorage'dan al
    if (!token) {
      throw new Error('Yetkisiz erişim: Token bulunamadı'); // Token yoksa hata fırlat
    }
    const response = await axios.get(`${API_URL}/profile`, {
      headers: {
        Authorization: `Bearer ${token}`, // Token'ı Authorization başlığına ekle
      },
    });
    return response.data; // Profil bilgilerini döner
  } catch (error) {
    throw new Error(error.response?.data || 'Profil bilgilerini alma hatası');
  }
};
export const updateProfile = async (user) => {
  try {
    const token = localStorage.getItem('token'); // Token'ı localStorage'dan al
    if (!token) {
      throw new Error('Yetkisiz erişim: Token bulunamadı'); // Token yoksa hata fırlat
    }
    const formData = new FormData();

    // Kullanıcı verilerini FormData'ya ekleyin
    Object.keys(user).forEach((key) => {
      formData.append(key, user[key]);
    });

    const response = await axios.put(`${API_URL}/profile`, formData, {
      headers: {
        Authorization: `Bearer ${token}`, // Token'ı Authorization başlığına ekle
        'Content-Type': 'multipart/form-data', // İçerik tipini ayarla
      },
    });

    return response.data; // Güncellenmiş kullanıcı bilgilerini döner
  } catch (error) {
    throw new Error(error.response?.data || 'Profil güncellerken hata oluştu');
  }
};
//---------------------------------------------------------------------------XX




//-------------------------------------------------------------------------------------//
export const getAllUsers = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Yetkisiz erişim: Token bulunamadı'); // Token yoksa hata fırlat
    }
    const response = await axios.get(`${API_URL}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Tüm kullanıcılar
  } catch (error) {
    console.error('Kullanıcılar çekilemedi:', error.message); // Hata mesajını konsola yaz
    throw new Error('Kullanıcılar çekilemedi');
  }
};
export const getUserById = async (id) => {
  try {
    const token = localStorage.getItem('token');

    if (!token) {
      throw new Error('Yetkisiz erişim: Token bulunamadı'); // Token yoksa hata fırlat
    }

    const response = await axios.get(`${API_URL}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Belirli kullanıcının verisini döndür
  } catch (error) {
    console.error('Kullanıcı alınamadı:', error.message);
    throw new Error('Kullanıcı alınamadı: ' + error.message);
  }
};
export const createUser = async (userData) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Yetkisiz erişim: Token bulunamadı'); // Token yoksa hata fırlat
    }
    const response = await axios.post(`${API_URL}/users`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Oluşturulan kullanıcı
  } catch (error) {
    console.error('Kullanıcı oluşturulamadı:', error.message);
    throw new Error('Kullanıcı oluşturulamadı');
  }
};
export const updateUser = async (id, userData) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Yetkisiz erişim: Token bulunamadı'); // Token yoksa hata fırlat
    }
    const response = await axios.put(`${API_URL}/users/${id}`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Güncellenen kullanıcı
  } catch (error) {
    console.error('Kullanıcı güncellenemedi:', error.message);
    throw new Error('Kullanıcı güncellenemedi');
  }
};
export const deleteUser = async (id) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Yetkisiz erişim: Token bulunamadı'); // Token yoksa hata fırlat
    }
    await axios.delete(`${API_URL}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Kullanıcı silinemedi:', error.message);
    throw new Error('Kullanıcı silinemedi');
  }
};
//--------------------------------------------------------------------XX




// Admin işlemi: Admin paneline erişim--------------------------------//
export const getAdminPanel = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/admin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Admin panel verileri
  } catch (error) {
    throw new Error('Admin paneline erişim hatası');
  }
};
//--------------------------------------------------------------------XX




// Tüm haberleri alma--------------------------------------------------//
export const getNews = async () => {
  try {
    const response = await axios.get(`${API_URL}/news`); // Token kontrolü kaldırıldı
    return response.data; // Tüm haberler
  } catch (error) {
    console.error('Haberler çekilemedi:', error.message);
    throw new Error('Haberler çekilemedi');
  }
};
// Belirli bir haberi alma
export const getNewsById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/news/${id}`); // Token kontrolü kaldırıldı
    return response.data; // Belirli haberin verisini döndür
  } catch (error) {
    console.error('Haber alınamadı:', error.message);
    throw new Error('Haber alınamadı');
  }
};
// Yeni haber ekleme
export const createNews = async (newsData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/news`, newsData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Yeni haber
  } catch (error) {
    console.error('Haber eklenemedi:', error.message);
    throw new Error('Haber eklenemedi');
  }
};
// Haber güncelleme
export const updateNews = async (id, newsData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.put(`${API_URL}/news/${id}`, newsData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Güncellenen haber
  } catch (error) {
    console.error('Haber güncellenemedi:', error.message);
    throw new Error('Haber güncellenemedi');
  }
};
// Haber silme
export const deleteNews = async (id) => {
  try {
    const token = localStorage.getItem('token');
    await axios.delete(`${API_URL}/news/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Haber silinemedi:', error.message);
    throw new Error('Haber silinemedi');
  }
};
//-----------------------------------------------------------------------XX




// Tüm duyuruları alma
export const getAnnouncements = async () => {
  try {
    const response = await axios.get(`${API_URL}/announcements`);
    return response.data; // Tüm duyurular
  } catch (error) {
    console.error('Duyurular çekilemedi:', error.message);
    throw new Error('Duyurular çekilemedi');
  }
};
// Belirli bir duyuruyu alma
export const getAnnouncementById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/announcements/${id}`);
    return response.data; // Belirli duyurunun verisini döndür
  } catch (error) {
    console.error('Duyuru alınamadı:', error.message);
    throw new Error('Duyuru alınamadı');
  }
};
// Yeni duyuru ekleme
export const createAnnouncement = async (announcementData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/announcements`, announcementData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // JSON formatında veri gönderiyoruz
      },
    });
    return response.data; // Yeni duyuru
  } catch (error) {
    console.error('Duyuru eklenemedi:', error.message);
    throw new Error('Duyuru eklenemedi');
  }
};
// Duyuru güncelleme
export const updateAnnouncement = async (id, announcementData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.put(`${API_URL}/announcements/${id}`, announcementData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // JSON formatında veri gönderiyoruz
      },
    });
    return response.data; // Güncellenen duyuru
  } catch (error) {
    console.error('Duyuru güncellenemedi:', error.message);
    throw new Error('Duyuru güncellenemedi');
  }
};
// Duyuru silme
export const deleteAnnouncement = async (id) => {
  try {
    const token = localStorage.getItem('token');
    await axios.delete(`${API_URL}/announcements/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Duyuru silinemedi:', error.message);
    throw new Error('Duyuru silinemedi');
  }
};
//----------------------------------------------------------------------------------XX




// Şubeleri listeleme------------------------------------------------------------------//
export const getBranches = async () => {
  try {
    const response = await axios.get(`${API_URL}/branches`);
    return response.data; // Tüm şubeler
  } catch (error) {
    console.error('Şubeler çekilemedi:', error.message);
    throw new Error('Şubeler çekilemedi');
  }
};
// Belirli bir şubeyi alma
export const getBranchById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/branches/${id}`);
    return response.data; // Belirli şubenin verisini döndür
  } catch (error) {
    console.error('Şube alınamadı:', error.message);
    throw new Error('Şube alınamadı');
  }
};
// Yeni şube ekleme
export const createBranch = async (branchData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/branches`, branchData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Yeni eklenen şube
  } catch (error) {
    console.error('Şube eklenemedi:', error.message);
    throw new Error('Şube eklenemedi');
  }
};
// Şube güncelleme
export const updateBranch = async (id, branchData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.put(`${API_URL}/branches/${id}`, branchData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Güncellenen şube
  } catch (error) {
    console.error('Şube güncellenemedi:', error.message);
    throw new Error('Şube güncellenemedi');
  }
};
// Şube silme
export const deleteBranch = async (id) => {
  try {
    const token = localStorage.getItem('token');
    await axios.delete(`${API_URL}/branches/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Şube silinemedi:', error.message);
    throw new Error('Şube silinemedi');
  }
};
//-----------------------------------------------------------------------------XX



// Tüm galerileri alma-----------------------------------------------------------//
export const getAllGalleries = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/galleries`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Galerilerin listesini döndür
  } catch (error) {
    console.error('Galeriler alınamadı:', error.message);
    throw new Error('Galeriler alınamadı');
  }
};
// Belirli bir galeriyi alma
export const getGalleryById = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/galleries/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Belirli galerinin verisini döndür
  } catch (error) {
    console.error('Galeri alınamadı:', error.message);
    throw new Error('Galeri alınamadı');
  }
};
// Galeri ekleme
export const createGallery = async (galleryData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/galleries`, galleryData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // Form-data kullanıyorsanız bu başlığı ekleyin
      },
    });
    return response.data; // Yeni eklenen galeri verisini döndür
  } catch (error) {
    console.error('Galeri eklenemedi:', error.message);
    throw new Error('Galeri eklenemedi');
  }
};
// Galeri güncelleme
export const updateGallery = async (id, updatedData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.put(`${API_URL}/galleries/${id}`, updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Güncellenmiş galeri verisini döndür
  } catch (error) {
    console.error('Galeri güncellenemedi:', error.message);
    throw new Error('Galeri güncellenemedi');
  }
};
// Galeri silme
export const deleteGallery = async (id) => {
  try {
    const token = localStorage.getItem('token');
    await axios.delete(`${API_URL}/galleries/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Galeri silinemedi:', error.message);
    throw new Error('Galeri silinemedi');
  }
};
//------------------------------------------------------------------------------XX



// Tüm statik metinleri alma----------------------------------------------------//
export const getAllStaticTexts = async () => {
  try {
    const response = await axios.get(`${API_URL}/static_texts`); // Token kontrolü kaldırıldı
    return response.data; // Statik metinlerin listesini döndür
  } catch (error) {
    console.error('Statik metinler alınamadı:', error.message);
    throw new Error('Statik metinler alınamadı');
  }
};
// Belirli bir statik metni alma
export const getStaticTextById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/static_texts/${id}`); // Token kontrolü kaldırıldı
    return response.data; // Belirli statik metnin verisini döndür
  } catch (error) {
    console.error('Statik metin alınamadı:', error.message);
    throw new Error('Statik metin alınamadı');
  }
};
// Statik metin ekleme
export const createStaticText = async (newData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/static_texts`, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Yeni eklenen statik metin verisini döndür
  } catch (error) {
    console.error('Statik metin eklenemedi:', error.message);
    throw new Error('Statik metin eklenemedi');
  }
};
// Statik metin güncelleme
export const updateStaticText = async (id, updatedData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.put(`${API_URL}/static_texts/${id}`, updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Güncellenmiş statik metin verisini döndür
  } catch (error) {
    console.error('Statik metin güncellenemedi:', error.message);
    throw new Error('Statik metin güncellenemedi');
  }
};
// Statik metin silme
export const deleteStaticText = async (id) => {
  try {
    const token = localStorage.getItem('token');
    await axios.delete(`${API_URL}/static_texts/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Statik metin silinemedi:', error.message);
    throw new Error('Statik metin silinemedi');
  }
};
//-----------------------------------------------------------------------------XX