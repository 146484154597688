import React, { useState, useEffect } from 'react';
import '../css/AdminPanel.css'; // Stil dosyanızı buraya ekleyin
import { Tab, Tabs } from 'react-bootstrap';
import UserList from '../pages/UserList'
import AnnouncementList from './AnnouncementList';
import NewsList from './NewsList';
import BranchesList from './BranchesList';
import GalleryList from './GalleryList';
import AboutEdit from './AboutEdit';
import ContactEdit from './ContactEdit';
import MessageOfPresidentEdit from './MessageOfPresidentEdit';

function AdminPanel() {
    const [activeTab, setActiveTab] = useState('about');
    return (
        <div className="admin-panel">
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
                <Tab eventKey="about" title="Hakkımızda">
                    <AboutEdit />
                </Tab>
                <Tab eventKey="branches" title="Şubelerimiz">
                    <BranchesList />
                </Tab>
                <Tab eventKey="contact" title="İletişim">
                    <ContactEdit />
                </Tab>
                <Tab eventKey="news" title="Haberler">
                    <NewsList />
                </Tab>
                <Tab eventKey="announcements" title="Duyurular">
                    <AnnouncementList />
                </Tab>
                <Tab eventKey="message" title="Başkanın Mesajı">
                    <MessageOfPresidentEdit />
                </Tab>
                <Tab eventKey="galery" title="Galeri">
                    <GalleryList />
                </Tab>
                <Tab eventKey="users" title="Üyeler">
                    <UserList />
                </Tab>
            </Tabs>
        </div>
    );
}

export default AdminPanel;
